import React, { useEffect, useState } from 'react';
import {
  Container,
  SampleContainer,
  SettingBox,
  SettingCounter,
  SettingHeader,
  SettingLabelBox,
  SettingsContainer,
} from '../StyledComponents/SampleStyledComponents';
import Sample from '../../images/details/survey-sample.png';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FullModule, SurveyQuestions } from '../../types';
import { updateSurveyQuestions } from '../../api/apiClient';
import sanitizeHtml from 'sanitize-html';
import { styled } from '@mui/material/styles';

interface Props {
  surveyQuestions: string[];
}
export const QuestionInputContainer = styled('div')({
  padding: '20px',
  borderRadius: '5px',
  border: '1px solid #818893',
  background: '#FFF',
  lineHeight: '24px',
});

export const CustomSpan = styled('span')({
  '&:empty::before': {
    content: "'[fill in the blank here with your own question]'",
    display: 'inline',
    cursor: 'text',
  },
  '&:empty:focus::before': {
    content: "'start typing...'",
    cursor: 'text',
  },
  '&:focus': {
    outline: '0px solid transparent',
  },
});

function SurveyQuestionScreen({ surveyQuestions }: Props) {
  const { moduleId } = useParams();
  const queryClient = useQueryClient();
  const [userInput, setUserInput] = useState(surveyQuestions[0]);
  const {
    mutate: updateSurveyQuestionsMutation,
    isPending: isUpdateSurveyQuestionsLoading,
  } = useMutation({
    mutationFn: (surveyQuestions: SurveyQuestions) =>
      updateSurveyQuestions(surveyQuestions),
  });

  const onContentBlur = React.useCallback(
    (e: React.FocusEvent<HTMLSpanElement>): void => {
      const sanitizeConf = {
        allowedTags: [],
      };
      const text = sanitizeHtml(e.currentTarget.innerHTML, sanitizeConf);
      setUserInput(text);
      const moduleData: FullModule | undefined = queryClient.getQueryData([
        'moduleDataById',
      ]);
      if (
        (moduleData?.surveyQuestions[0] !== text ||
          !moduleData?.surveyQuestions) &&
        moduleId
      ) {
        updateSurveyQuestionsMutation(
          {
            moduleId: moduleId,
            surveyQuestions: [text],
          },
          {
            onSuccess: ({ data }) => {
              queryClient.invalidateQueries({
                queryKey: ['moduleDataById', moduleId],
              });
              queryClient.setQueryData(['moduleDataById'], data);
              console.log('onSuccess updateSurveyQuestionsMutation');
            },
            onError: (error) => alert(error),
          },
        );
      }
    },
    [],
  );
  const renderText = (): string => {
    return `${userInput}`;
  };

  return (
    <Container>
      <SampleContainer>
        <img src={Sample} alt="Character info" />
      </SampleContainer>
      <SettingsContainer>
        <SettingBox>
          <SettingLabelBox>
            <SettingHeader>Question</SettingHeader>
            <SettingCounter>{userInput.length}/60</SettingCounter>
          </SettingLabelBox>
          <QuestionInputContainer>
            <b>How confident are you in </b>
            <CustomSpan
              dangerouslySetInnerHTML={{ __html: renderText() }}
              contentEditable="plaintext-only"
              onBlur={onContentBlur}
            />
            <b>?</b>
          </QuestionInputContainer>
        </SettingBox>
      </SettingsContainer>
    </Container>
  );
}

export default SurveyQuestionScreen;
