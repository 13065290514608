import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Box, Button } from '@mui/material';
import ChevronRight from '../svg/PlayerSvg/ChevronRight';
import ChevronLeft from '../svg/PlayerSvg/ChevronLeft';
import BackgroundWrapper from './BackgroundWrapper';
import ForegroundWrapper from './ForegroundWrapper';

interface LayoutProps {
  children: ReactNode;
  activeStep: number;
  handleBack: () => void;
  handleNext: () => void;
  isNextButtonVisible: boolean;
}

const BackButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.10)',
  borderRadius: '50%',
  width: '65px',
  height: '65px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    backgroundColor: theme.palette.primary.main,
  },
}));

const NextButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '50px',
  padding: '19px 40px',
  color: theme.palette.primary.contrastText,
  fontWeight: 700,
  fontSize: '20px',
  lineHeight: '27px',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const Layout = ({
  children,
  activeStep,
  handleBack,
  handleNext,
  isNextButtonVisible,
}: LayoutProps) => (
  <>
    <CssBaseline />

    {/* Background and Drawer */}
    <BackgroundWrapper>
      {/* Foreground */}
      <ForegroundWrapper>{children}</ForegroundWrapper>

      {/* Bottom Navigation */}
      <Box
        sx={{
          width: 'min(100%, 1094px)',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingTop: '30px',
          boxSizing: 'border-box',
        }}
      >
        <BackButton
          style={{ opacity: activeStep === 0 ? 0 : 1 }}
          disabled={activeStep === 0}
          onClick={handleBack}
        >
          <ChevronLeft />
        </BackButton>

        {isNextButtonVisible && (
          <NextButton onClick={handleNext}>
            Next &nbsp;&nbsp;
            <ChevronRight />
          </NextButton>
        )}
      </Box>
    </BackgroundWrapper>
  </>
);

export default Layout;
