import React, { useRef, useEffect } from 'react';

const Webcam = () => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    let videoStream: MediaStream | null = null;
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          if (videoRef.current) {
            videoRef.current.srcObject = stream;
            videoStream = stream;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    return () => {
      videoStream?.getTracks().forEach(function (track) {
        track.stop();
      });
    };
  }, []);

  return (
    <video
      ref={videoRef}
      autoPlay
      playsInline
      style={{ height: '172px', width: '172px', zIndex: 3, objectFit: 'cover' }}
    />
  );
};

export default Webcam;
