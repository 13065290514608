import React, { useState } from 'react';
import Sample from '../../images/details/characterIntro.png';
import {
  Container,
  SampleContainer,
  SettingsContainer,
  SettingBox,
  SettingLabelBox,
  SettingHeader,
  SettingCounter,
  CustomTextField,
} from '../StyledComponents/SampleStyledComponents';
import { FullModule, VoiceOvers } from '../../types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateVoiceOvers } from '../../api/apiClient';
import { useParams } from 'react-router-dom';

function CharacterInfoScreen({
  characterVoiceOver,
}: {
  characterVoiceOver: string;
}) {
  const [narratorDescription, setNarratorDescription] =
    useState(characterVoiceOver);

  const { moduleId } = useParams();
  const queryClient = useQueryClient();

  const {
    mutate: updateCharacterVOMutation,
    isPending: updateCharacterVOMutationLoading,
  } = useMutation({
    mutationFn: (voiceOvers: VoiceOvers) => updateVoiceOvers(voiceOvers),
  });

  const onBlurCharacterVO = (text: string) => {
    setNarratorDescription(text);
    const moduleData: FullModule | undefined = queryClient.getQueryData([
      'moduleDataById',
    ]);
    const newVoiceOvers = JSON.parse(JSON.stringify(moduleData?.voiceOvers));
    newVoiceOvers.characterVO = text;
    if (moduleData?.voiceOvers?.characterVO !== text && moduleId) {
      updateCharacterVOMutation(
        {
          moduleId: moduleId,
          voiceOvers: newVoiceOvers,
        },
        {
          onSuccess: ({ data }) => {
            queryClient.invalidateQueries({
              queryKey: ['moduleDataById', moduleId],
            });
            queryClient.setQueryData(['moduleDataById'], data);
            console.log('onSuccess updateCharacterVOMutation');
          },
          onError: (error) => alert(error),
        },
      );
    }
  };
  return (
    <Container>
      <SampleContainer>
        <img src={Sample} alt="Character info" />
      </SampleContainer>
      <SettingsContainer>
        <SettingBox>
          <SettingLabelBox>
            <SettingHeader>Narrator</SettingHeader>
            <SettingCounter>{narratorDescription.length}/60</SettingCounter>
          </SettingLabelBox>
          <CustomTextField
            id="outlined-multiline-static"
            fullWidth
            inputProps={{ maxLength: 60 }}
            multiline
            rows={4}
            value={narratorDescription}
            onChange={(e) => setNarratorDescription(e.target.value)}
            onBlur={(e) => onBlurCharacterVO(e.target.value)}
            placeholder={'Narrator'}
          />
        </SettingBox>
      </SettingsContainer>
    </Container>
  );
}

export default CharacterInfoScreen;
