import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, Avatar } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { getModuleVoiceOver } from '../../api/apiClient';

import alisha from '../../images/characters/alisha.png';
import emma from '../../images/characters/emma.png';
import evelyn from '../../images/characters/evelyn.png';
import jimmy from '../../images/characters/jimmy.png';
import parker from '../../images/characters/parker.png';
import sally from '../../images/characters/sally.png';
import sana from '../../images/characters/sana.png';
import will from '../../images/characters/will.png';

const characters = {
  Alisha: alisha,
  Emma: emma,
  Evelyn: evelyn,
  Jimmy: jimmy,
  Parker: parker,
  Sally: sally,
  Sana: sana,
  Will: will,
};

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '50px',
  color: '#FFF',
  '@media (max-width:375px)': {
    gap: '30px',
  },
});

const MainHeader = styled(Typography)({
  fontWeight: 700,
  fontSize: 'clamp(1.875rem, 1.3248rem + 2.3474vw, 3.4375rem)',
  letterSpacing: '-0.55px',
  lineHeight: '55px',
});

const CharacterContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '50px',
  '@media (max-width:768px)': {
    flexWrap: 'wrap',
  },
  '@media (max-width:375px)': {
    gap: '30px',
  },
});

const CharacterAvatar = styled(Avatar)({
  width: '228px',
  height: '228px',
  '@media (max-width:768px)': {
    width: '168px',
    height: '168px',
  },
  '@media (max-width:375px)': {
    width: '148px',
    height: '148px',
  },
});

const CharacterSkillsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  width: '100%',
});

const Skill = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '15px',
  border: '3px solid rgba(255, 255, 255, 0.10)',
  padding: '22px 40px',
  width: 'min(100%, 500px)',
  '@media (max-width:768px)': {
    padding: '22px',
  },
});

const CheckIcon = styled(CheckCircleIcon)({
  color: '#20BF6F',
  marginRight: '20px',
});

const CancelFilledIcon = styled(CancelIcon)({
  color: '#E21D1D',
  marginRight: '20px',
});

const RegularText = styled(Typography)({
  fontWeight: 450,
  fontSize: '20px',
  lineHeight: '24px',
  letterSpacing: '-0.2px',
  fontFamily: 'CircularStd-Medium',
  color: '#FFFFFF',
});

function CharacterIntro({
  communicationStyleA,
  communicationStyleB,
  communicationStyleC,
  characterName,
  moduleId,
}: {
  communicationStyleA: string;
  communicationStyleB: string;
  communicationStyleC: string;
  characterName: string;
  moduleId: string;
}) {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [voiceOverUrl, setVoiceOverUrl] = useState<string | null>(null);

  useEffect(() => {
    const loadVoiceOver = async () => {
      try {
        // Fetch the voiceover data
        const response = await getModuleVoiceOver({
          moduleId: moduleId, // Adjust moduleId as needed
          voiceOverKey: 'characterVO', // Adjust the voiceOverKey as needed
        });

        if (response.data.url) {
          // Set the voiceover URL
          setVoiceOverUrl(response.data.url);
        }
      } catch (error) {
        console.error('Error loading voiceover:', error);
      }
    };

    loadVoiceOver();
  }, [moduleId]);

  useEffect(() => {
    const audioElement = audioRef.current;

    if (audioElement && voiceOverUrl) {
      // Set the audio source using the voiceover URL
      audioElement.src = voiceOverUrl;

      // Autoplay the audio when the component mounts
      audioElement.play().catch((error) => {
        console.error('Audio playback error:', error);
      });
    }
  }, [voiceOverUrl]);

  return (
    <Container>
      <MainHeader>{characterName}</MainHeader>
      <CharacterContainer>
        <CharacterAvatar
          src={characters[characterName as keyof typeof characters]}
          alt={characterName}
        />
        <CharacterSkillsContainer>
          <Skill>
            <CheckIcon />
            <RegularText>
              Be {communicationStyleA.toLocaleLowerCase()}
            </RegularText>
          </Skill>
          <Skill>
            <CancelFilledIcon />
            <RegularText>
              Avoid being {communicationStyleB.toLocaleLowerCase()}
            </RegularText>
          </Skill>
          <Skill>
            <CancelFilledIcon />
            <RegularText>
              Avoid being {communicationStyleC.toLocaleLowerCase()}
            </RegularText>
          </Skill>
        </CharacterSkillsContainer>
      </CharacterContainer>
      <audio ref={audioRef} controls style={{ display: 'none' }} />
    </Container>
  );
}

export default CharacterIntro;
