import React, { useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Container,
  CustomButton,
} from '../StyledComponents/SampleStyledComponents';
import BuildModuleModal from './BuildModuleModal';
import ModuleInProgressModal from './ModuleInProgressModal';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getModuleCSV,
  setModuleAssets,
  setModuleVoiceOver,
} from '../../api/apiClient';
import { useParams } from 'react-router-dom';
import ModuleUnfinishedModal from './ModuleUnfinishedModal';
import { FullModule } from '../../types';
import { useNavigate } from 'react-router-dom';

function FinishModuleScreen() {
  const { moduleId } = useParams();
  const [modalVisible, setModalVisible] = useState(false);
  const [moduleInProgressModalVisible, setModuleInProgressModalVisible] =
    useState(false);
  const [moduleUnfinishedModalVisible, setModuleUnfinishedModalVisible] =
    useState(false);
  const [unfinishedStepsList, setUnfinishedStepsList] = useState<string[]>([]);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const {
    mutate: setModuleAssetsMutation,
    isPending: isSetModuleAssetsLoading,
  } = useMutation({
    mutationFn: (moduleId: string) => setModuleAssets(moduleId),
  });

  const {
    mutate: setModuleVoiceOverMutation,
    isPending: isSetModuleVoiceOverLoading,
  } = useMutation({
    mutationFn: (moduleId: string) => setModuleVoiceOver(moduleId),
  });

  const { isLoading: isGetModuleCSVDataLoading, refetch } = useQuery({
    queryKey: ['moduleCSVData', moduleId],
    queryFn: () => getModuleCSV(moduleId ? moduleId : ''),
    enabled: false,
    refetchOnWindowFocus: false,
  });

  const downloadCsv = (data: string) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    const fileName = `csv.csv`;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const handleDownloadCsv = () => {
    refetch().then((response) => {
      downloadCsv(response.data?.data);
    });
  };

  const handleBuildModule = () => {
    setModuleAssetsMutation(moduleId ? moduleId : '', {
      onSuccess: () => {
        setModalVisible(false);
        setModuleInProgressModalVisible(true);
      },
      onError: (err) => {
        alert(err);
      },
    });
    setModuleVoiceOverMutation(moduleId ? moduleId : '', {
      onSuccess: () => {
        console.log('onSuccess setModuleVoiceOverMutation');
      },
      onError: (err) => {
        alert(err);
      },
    });
  };

  const onBuildModule = () => {
    const moduleData: FullModule | undefined = queryClient.getQueryData([
      'moduleDataById',
    ]);
    const unfinishedSteps: string[] = [];
    if (moduleData) {
      if (!moduleData.topic) {
        unfinishedSteps.push('1. Scene Setup');
      }
      if (!moduleData.character || !moduleData.environment) {
        unfinishedSteps.push(
          '2. Building The Scene (check character or environment)',
        );
      }
      if (
        !moduleData.moduleScript ||
        !(moduleData.keyMoments && moduleData?.keyMoments?.length)
      ) {
        unfinishedSteps.push(
          '3. Script (generate Script or select Key learning moments)',
        );
      }
      //check feedback??
      if (
        !moduleData.summary ||
        !moduleData.learningObjective ||
        !(moduleData.surveyQuestions && moduleData.surveyQuestions?.length)
      ) {
        unfinishedSteps.push(
          '5. Details (check Module description, Learning objective or Pre-experience survey question)',
        );
      }
    }
    setUnfinishedStepsList(unfinishedSteps);
    if (unfinishedSteps.length) {
      setModuleUnfinishedModalVisible(true);
    } else {
      setModalVisible(true);
    }
    console.log(moduleData);
  };

  return (
    <Container>
      <CustomButton
        variant="outlined"
        endIcon={<DownloadIcon />}
        onClick={handleDownloadCsv}
        disabled={isGetModuleCSVDataLoading}
      >
        Download content csv
      </CustomButton>
      <CustomButton variant="contained" onClick={onBuildModule}>
        Build module
      </CustomButton>
      <ModuleInProgressModal
        open={moduleInProgressModalVisible}
        onClose={() => setModuleInProgressModalVisible(false)}
        onSubmit={() => {
          navigate('/modules');
        }}
      />
      <ModuleUnfinishedModal
        open={moduleUnfinishedModalVisible}
        onClose={() => setModuleUnfinishedModalVisible(false)}
        onSubmit={() => setModuleUnfinishedModalVisible(false)}
        unfinishedStepsList={unfinishedStepsList}
      />
      <BuildModuleModal
        open={modalVisible}
        onClose={() => setModalVisible(false)}
        onSubmit={handleBuildModule}
        isBuildModuleLoading={isSetModuleAssetsLoading}
      />
    </Container>
  );
}

export default FinishModuleScreen;
