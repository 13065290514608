import React, { useEffect, useState } from 'react';
import { Button, IconButton, Popover, CircularProgress } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  FullModule,
  KeyMoments as KeyMomentsType,
  ModuleScriptType,
} from '../../types';
import { updateKeyMoments, updateModuleScript } from '../../api/apiClient';

const DeleteMoment = ({ momentId }: { momentId: number }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const idPopover = open ? 'simple-popover' : undefined;

  const { moduleId } = useParams();
  const queryClient = useQueryClient();
  const {
    mutate: deleteMomentInModuleScriptMutation,
    isPending: IsDeleteMomentInModuleScriptMutationLoading,
  } = useMutation({
    mutationFn: (moduleScript: ModuleScriptType) =>
      updateModuleScript(moduleScript),
  });

  const {
    mutate: deleteKeyMomentsMutation,
    isPending: isDeleteKeyMomentsLoading,
  } = useMutation({
    mutationFn: (keyMoments: KeyMomentsType) => updateKeyMoments(keyMoments),
  });

  const deleteKeyMoments = (moduleData: FullModule) => {
    if (moduleData?.keyMoments) {
      const newKeyMoments = JSON.parse(JSON.stringify(moduleData?.keyMoments));
      const updatedKeyMoments = newKeyMoments.filter(
        (keyMoment: number) => keyMoment !== momentId,
      );
      deleteKeyMomentsMutation(
        {
          moduleName: moduleData?.moduleName,
          moduleId: moduleData?.moduleId ? moduleData?.moduleId : '',
          keyMoments: updatedKeyMoments,
        },
        {
          onSuccess: ({ data }) => {
            queryClient.invalidateQueries({
              queryKey: ['moduleDataById', moduleId],
            });
            queryClient.setQueryData(['moduleDataById'], data);
            console.log('onSuccess deleteKeyMomentsMutation');
          },
          onError: (error) => alert(error),
        },
      );
    }
  };

  const onDeleteMoment = () => {
    const moduleData: FullModule | undefined = queryClient.getQueryData([
      'moduleDataById',
    ]);
    const newModuleScript = JSON.parse(
      JSON.stringify(moduleData?.moduleScript),
    );
    newModuleScript.pathA?.splice(momentId, 1);
    newModuleScript.pathB?.splice(momentId, 1);
    newModuleScript.pathC?.splice(momentId, 1);
    deleteMomentInModuleScriptMutation(
      {
        moduleName: moduleData?.moduleName,
        moduleId: moduleData?.moduleId ? moduleData?.moduleId : '',
        moduleScript: newModuleScript,
      },
      {
        onSuccess: ({ data }) => {
          handleClose();
          queryClient.invalidateQueries({
            queryKey: ['moduleDataById', moduleId],
          });
          queryClient.setQueryData(['moduleDataById'], data);
          console.log('onSuccess deleteMomentInModuleScriptMutation');
          deleteKeyMoments(data);
        },
        onError: (error) => alert(error),
      },
    );
  };

  return (
    <>
      <IconButton
        aria-describedby={idPopover}
        aria-label="moment-menu"
        size="small"
        onClick={handleClick}
      >
        <MoreHorizIcon sx={{ color: '#C8C8C8' }} />
      </IconButton>
      <Popover
        id={idPopover}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '10px',
            background: '#FFF',
            boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.10)',
            padding: '20px 30px',
          },
        }}
      >
        <Button
          variant="text"
          style={{
            textTransform: 'none',
            padding: '0',
            marginRight: '20px',
          }}
          onClick={onDeleteMoment}
        >
          {(IsDeleteMomentInModuleScriptMutationLoading ||
            isDeleteKeyMomentsLoading) && (
            <CircularProgress size={20} sx={{ marginRight: '15px' }} />
          )}
          Delete moment
        </Button>
        <IconButton size="small" onClick={handleClose}>
          <CloseIcon sx={{ color: '#030303' }} />
        </IconButton>
      </Popover>
    </>
  );
};

export default DeleteMoment;
