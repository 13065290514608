import React, { ReactElement, useContext, useState } from 'react';
import {
  Popover,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
  CircularProgress,
} from '@mui/material';
import { ModuleEditorContext } from '../../reducers/ModuleEditorReducer';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import CheckIcon from '@mui/icons-material/Check';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { deleteModuleById } from '../../api/apiClient';
import { useNavigate } from 'react-router-dom';
import { Module } from '../../types';

interface Props {
  onClose: (value: string) => void;
  showEditModuleNameModal: () => void;
  selectedValue: string;
  open: boolean;
  module: Module;
  anchorEl: HTMLButtonElement | null;
}

function ModuleMenuPopover(props: Props) {
  const {
    onClose,
    selectedValue,
    open,
    module,
    anchorEl,
    showEditModuleNameModal,
  } = props;
  const [isShareLinkCopied, setIsShareLinkCopied] = useState(false);
  const context = useContext(ModuleEditorContext);
  if (!context) {
    throw new Error(
      'ModuleDisplayCard must be used within a ModuleEditorContext.Provider',
    );
  }
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const handleClose = () => {
    onClose(selectedValue);
  };

  const {
    mutate: deleteModuleByIdMutation,
    isPending: isDeleteModuleByIdLoading,
  } = useMutation({
    mutationFn: (moduleId: string) => deleteModuleById(moduleId),
  });

  const handleListItemClick = (action: string, module: Module) => {
    switch (action) {
      case 'editModule':
        navigate(`/module/${module.moduleId}`);
        break;
      case 'editName':
        showEditModuleNameModal();
        break;
      case 'copyLink':
        navigator.clipboard.writeText(
          `${window.location.origin}/module/${module.moduleId}`,
        );
        setIsShareLinkCopied(true);
        setTimeout(() => {
          handleClose();
          setIsShareLinkCopied(false);
        }, 1000);
        break;
      case 'delete':
        deleteModuleByIdMutation(module.moduleId, {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['moduleListData'] });
            handleClose();
          },
          onError: (err) => alert(err),
        });
        break;
      default:
        console.log('default');
        break;
    }
  };

  const options: string[] = [
    'Edit module',
    'Edit module name',
    'Copy share link',
    'Delete module',
  ];

  const icons: ReactElement[] = [
    <AppRegistrationIcon sx={{ color: '#30343A' }} key={1} />,
    <EditOutlinedIcon sx={{ color: '#30343A' }} key={2} />,
    isShareLinkCopied ? (
      <CheckIcon sx={{ color: '#20BF6F' }} />
    ) : (
      <LinkOutlinedIcon sx={{ color: '#30343A' }} key={3} />
    ),
    isDeleteModuleByIdLoading ? (
      <CircularProgress size={16} />
    ) : (
      <DeleteOutlineOutlinedIcon sx={{ color: '#30343A' }} key={5} />
    ),
  ];

  const actions: string[] = ['editModule', 'editName', 'copyLink', 'delete'];

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '20px',
          background: '#FFF',
          boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.10)',
          padding: '45px 30px',
        },
      }}
    >
      <List sx={{ padding: '0' }}>
        {options.map((option, idx) => (
          <ListItem
            disableGutters
            key={idx}
            sx={{
              padding: `${idx ? '15px' : '0'} 0 ${
                options.length - 1 !== idx ? '15px' : '0'
              } 0`,
              borderBottom: `1px solid ${
                options.length - 1 !== idx
                  ? 'rgba(0, 0, 0, 0.05)'
                  : 'transparent'
              }`,
            }}
          >
            <ListItemButton
              autoFocus
              onClick={() => handleListItemClick(actions[idx], module)}
              sx={{
                padding: '0 15px 0 0',
                gap: '10px',
                '&:hover': { backgroundColor: 'transparent' },
              }}
            >
              <ListItemAvatar>
                <Avatar
                  sx={{
                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                    height: '50px',
                    width: '50px',
                  }}
                >
                  {icons[idx]}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                sx={{
                  '& .MuiTypography-root': {
                    fontSize: '20px',
                    fontFamily: 'CircularStd-Medium',
                    letterSpacing: '-0.6px',
                  },
                }}
                primary={option}
              />
            </ListItemButton>
            <Divider
              sx={{
                borderColor: 'rgba(0, 0, 0, 0.05)',
              }}
            />
          </ListItem>
        ))}
      </List>
    </Popover>
  );
}

export default ModuleMenuPopover;
