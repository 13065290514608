import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import Shape from '../../images/PlayerImages/shape.png';
import { getModuleVoiceOver } from '../../api/apiClient';

const Container = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  color: '#FFF',
  '@media (max-width:375px)': {
    flexDirection: 'column',
    gap: '20px',
  },
});

const DescriptionContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '35px',
  width: 'min(50%, 380px)',
  '@media (max-width:375px)': {
    width: '100%',
  },
});

const MainHeader = styled(Typography)({
  fontWeight: 700,
  fontSize: 'clamp(1.875rem, 1.3248rem + 2.3474vw, 3.4375rem)',
  letterSpacing: '-1.65px',
  lineHeight: '55px',
});

const RegularText = styled(Typography)({
  fontWeight: 450,
  fontSize: '20px',
  lineHeight: '27px',
  letterSpacing: '-0.2px',
  fontFamily: 'CircularStd-Medium',
  color: '#D5D7D8',
});

const OptionsContainer = styled(Box)({
  position: 'relative',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
  flexWrap: 'wrap',
  gap: '10px',
  paddingBottom: '45px',
  '&::before': {
    content: "''",
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${Shape})`,
  },
  '@media (max-width:1024px)': {
    width: '45%',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    paddingBottom: '0',
  },
  '@media (max-width:375px)': {
    width: '100%',
    paddingTop: '220px',
    alignItems: 'center',
  },
});

const OptionBox = styled(Box)({
  borderRadius: '15px',
  border: '1px solid rgba(255, 255, 255, 0.50)',
  background: 'rgba(0, 0, 0, 0.35)',
  padding: '40px 20px',
  fontSize: '20px',
  color: '#A1A6AD',
  textTransform: 'none',
  '@media (max-width:1024px)': {
    width: '100%',
    padding: '20px',
  },
  '@media (max-width:768px)': {
    padding: '10px',
    fontSize: '18px',
  },
  '@media (max-width:375px)': {
    padding: '4px',
    fontSize: '15px',
    minWidth: '240px',
  },
});

function ExperienceIntro({
  moduleId,
  handleNext,
}: {
  moduleId: string;
  handleNext: () => void;
}) {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [voiceOverUrl, setVoiceOverUrl] = useState<string | null>(null);

  useEffect(() => {
    const loadVoiceOver = async () => {
      try {
        // Fetch the voiceover data
        const response = await getModuleVoiceOver({
          moduleId: moduleId, // Adjust moduleId as needed
          voiceOverKey: 'onboardingVO', // Adjust the voiceOverKey as needed
        });

        if (response.data.url) {
          // Set the voiceover URL
          setVoiceOverUrl(response.data.url);
        }
      } catch (error) {
        console.error('Error loading voiceover:', error);
      }
    };

    loadVoiceOver();
  }, [moduleId]);

  useEffect(() => {
    const audioElement = audioRef.current;

    if (audioElement && voiceOverUrl) {
      // Set the audio source using the voiceover URL
      audioElement.src = voiceOverUrl;

      // Autoplay the audio when the component mounts
      audioElement.play().catch((error) => {
        console.error('Audio playback error:', error);
      });
    }
  }, [voiceOverUrl]);

  const handleChange = (value: string) => {
    console.log(value);
    handleNext();
  };

  return (
    <Container>
      <DescriptionContainer>
        <MainHeader>About the experience</MainHeader>
        <RegularText>
          At each stage of the conversation you will be presented with 3 options
          for how to respond.
        </RegularText>
        <RegularText>
          Choose the choice that you think is best and read it out loud.
        </RegularText>
      </DescriptionContainer>
      <OptionsContainer>
        {['Option A', 'Option B', 'Option C'].map((value) => (
          <OptionBox key={value}>{value}</OptionBox>
        ))}
      </OptionsContainer>
      <audio ref={audioRef} controls style={{ display: 'none' }} />
    </Container>
  );
}

export default ExperienceIntro;
