import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Checkbox, FormControlLabel, Box, Tooltip } from '@mui/material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import {
  Moment,
  KeyMoments as KeyMomentsType,
  FullModule,
  ModuleScriptType,
} from '../../../types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateKeyMoments, updateModuleScript } from '../../../api/apiClient';
import {
  MomentBox,
  MomentContainer,
  MomentHeaderBox,
  MomentHeader,
  MomentCardsContainer,
  HeaderContainer,
  MomentLabel,
  CustomTextField,
} from './styledComponents';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import type { Identifier, XYCoord } from 'dnd-core';
import AddNewMoment from '../AddNewMoment';
import DeleteMoment from '../DeleteMoment';
interface DragItem {
  index: number;
  id: string;
  type: string;
}

const ItemTypes = {
  CARD: 'card',
};
function LearnerText({
  value,
  setValue,
  save,
}: {
  value: string;
  setValue: (value: string) => void;
  save: (val: string) => void;
}) {
  return (
    <CustomTextField
      id="outlined-multiline-static"
      fullWidth
      inputProps={{ maxLength: 100 }}
      multiline
      rows={6}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={(e) => save(e.target.value)}
      placeholder={'Who do you think is the best candidate for the job?'}
      helperText={`${value.length}/100`}
    />
  );
}

function CharacterText({
  value,
  setValue,
  save,
}: {
  value: string;
  setValue: (value: string) => void;
  save: (val: string) => void;
}) {
  return (
    <CustomTextField
      id="outlined-multiline-static"
      fullWidth
      inputProps={{ maxLength: 300 }}
      multiline
      rows={12}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={(e) => save(e.target.value)}
      placeholder={'Who do you think is the best candidate for the job?'}
      helperText={`${value.length}/300`}
    />
  );
}

interface Props {
  pathA: Moment;
  pathB: Moment;
  pathC: Moment;
  momentNumber: number;
  module: FullModule;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  index: number;
  id: number;
}

export default function MomentCard({
  pathA,
  pathB,
  pathC,
  momentNumber,
  module,
  moveCard,
  index,
  id,
}: Props) {
  const dragRef = useRef<HTMLDivElement>(null);
  const previewRef = useRef<HTMLDivElement>(null);

  const [isChecked, setChecked] = useState(
    new Set(module.keyMoments).has(momentNumber),
  );
  const [editableTextPathALearner, setEditableTextPathALearner] = useState(
    pathA.learnerText,
  );
  const [editableTextPathBLearner, setEditableTextPathBLearner] = useState(
    pathB.learnerText,
  );
  const [editableTextPathCLearner, setEditableTextPathCLearner] = useState(
    pathC.learnerText,
  );
  const [editableTextPathACharacter, setEditableTextPathACharacter] = useState(
    pathA.characterText,
  );
  const [editableTextPathBCharacter, setEditableTextPathBCharacter] = useState(
    pathB.characterText,
  );
  const [editableTextPathCCharacter, setEditableTextPathCCharacter] = useState(
    pathC.characterText,
  );

  useEffect(() => {
    setEditableTextPathALearner(pathA.learnerText);
  }, [pathA.learnerText]);

  useEffect(() => {
    setEditableTextPathBLearner(pathB.learnerText);
  }, [pathB.learnerText]);

  useEffect(() => {
    setEditableTextPathCLearner(pathC.learnerText);
  }, [pathC.learnerText]);

  useEffect(() => {
    setEditableTextPathACharacter(pathA.characterText);
  }, [pathA.characterText]);

  useEffect(() => {
    setEditableTextPathBCharacter(pathB.characterText);
  }, [pathB.characterText]);

  useEffect(() => {
    setEditableTextPathCCharacter(pathC.characterText);
  }, [pathC.characterText]);

  const { moduleId } = useParams();
  const queryClient = useQueryClient();
  const {
    mutate: updateKeyMomentsMutation,
    isPending: isUpdateKeyMomentsLoading,
  } = useMutation({
    mutationFn: (keyMoments: KeyMomentsType) => updateKeyMoments(keyMoments),
  });

  useEffect(() => {
    if (module?.keyMoments && module?.keyMoments.length) {
      setChecked(false);
      setChecked(new Set(module?.keyMoments).has(momentNumber));
    }
  }, [module]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    const moduleData: FullModule | undefined = queryClient.getQueryData([
      'moduleDataById',
    ]);
    const newKeyMoments: Set<number> = new Set(moduleData?.keyMoments);
    if (event.target.checked) {
      newKeyMoments.add(momentNumber);
    } else {
      newKeyMoments.delete(momentNumber);
    }
    const sortedKeyMoments: number[] = Array.from(newKeyMoments).sort(
      (a: number, b: number) => a - b,
    );
    updateKeyMomentsMutation(
      {
        moduleName: module.moduleName,
        moduleId: module.moduleId,
        keyMoments: sortedKeyMoments,
      },
      {
        onSuccess: ({ data }) => {
          queryClient.invalidateQueries({
            queryKey: ['moduleDataById', moduleId],
          });
          queryClient.setQueryData(['moduleDataById'], data);
          console.log('onSuccess updateKeyMomentsMutation');
        },
        onError: (error) => alert(error),
      },
    );
  };

  const {
    mutate: updateLearnerAndCharacterTextInModuleScriptMutation,
    isPending: IsUpdateLearnerAndCharacterTextInModuleScriptMutationLoading,
  } = useMutation({
    mutationFn: (moduleScript: ModuleScriptType) =>
      updateModuleScript(moduleScript),
  });
  const updateLearnerAndCharacterTextInModuleScript = (
    path: string,
    momentNumber: number,
    newMoment: any,
  ) => {
    const moduleData: FullModule | undefined = queryClient.getQueryData([
      'moduleDataById',
    ]);
    const newModuleScript = JSON.parse(
      JSON.stringify(moduleData?.moduleScript),
    );
    newModuleScript[path][momentNumber] = newMoment;
    updateLearnerAndCharacterTextInModuleScriptMutation(
      {
        moduleName: moduleData?.moduleName,
        moduleId: moduleData?.moduleId ? moduleData?.moduleId : '',
        moduleScript: newModuleScript,
      },
      {
        onSuccess: ({ data }) => {
          queryClient.invalidateQueries({
            queryKey: ['moduleDataById', moduleId],
          });
          queryClient.setQueryData(['moduleDataById'], data);
          console.log(
            'onSuccess updateLearnerAndCharacterTextInModuleScriptMutation',
          );
        },
        onError: (error) => alert(error),
      },
    );
  };

  const {
    mutate: moveMomentsInModuleScriptMutation,
    isPending: IsMoveMomentsInModuleScriptMutationLoading,
  } = useMutation({
    mutationFn: (moduleScript: ModuleScriptType) =>
      updateModuleScript(moduleScript),
  });

  function array_move(
    arr: Moment[],
    old_index: number,
    new_index: number,
  ): Moment[] {
    if (new_index >= arr.length) {
      let k: number = new_index - arr.length + 1;
      while (k--) {
        arr.push({ learnerText: '', characterText: '', feedback: '' });
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  }
  const onMoveMomentsInModuleScript = (from: number, to: number) => {
    const moduleData: FullModule | undefined = queryClient.getQueryData([
      'moduleDataById',
    ]);
    const newModuleScript = JSON.parse(
      JSON.stringify(moduleData?.moduleScript),
    );
    newModuleScript.pathA = array_move(newModuleScript.pathA, from, to);
    newModuleScript.pathB = array_move(newModuleScript.pathB, from, to);
    newModuleScript.pathC = array_move(newModuleScript.pathC, from, to);

    moveMomentsInModuleScriptMutation(
      {
        moduleName: moduleData?.moduleName,
        moduleId: moduleData?.moduleId ? moduleData?.moduleId : '',
        moduleScript: newModuleScript,
      },
      {
        onSuccess: ({ data }) => {
          queryClient.invalidateQueries({
            queryKey: ['moduleDataById', moduleId],
          });
          queryClient.setQueryData(['moduleDataById'], data);
          console.log('onSuccess moveMomentsInModuleScriptMutation');
          changeKeyMoments(from, to, data);
        },
        onError: (error) => alert(error),
      },
    );
  };

  const {
    mutate: changeKeyMomentsMutation,
    isPending: isChangeKeyMomentsLoading,
  } = useMutation({
    mutationFn: (keyMoments: KeyMomentsType) => updateKeyMoments(keyMoments),
  });

  const changeKeyMoments = (
    from: number,
    to: number,
    moduleData: FullModule,
  ) => {
    if (moduleData.keyMoments) {
      const newKeyMoments = JSON.parse(JSON.stringify(moduleData.keyMoments));
      let isKeyMomentsUpdated = false;
      newKeyMoments.map((keyMoment: number, i: number) => {
        if (keyMoment === from) {
          newKeyMoments[i] = to;
          isKeyMomentsUpdated = true;
        }
        if (keyMoment === to) {
          newKeyMoments[i] = from;
          isKeyMomentsUpdated = true;
        }
      });
      if (isKeyMomentsUpdated) {
        changeKeyMomentsMutation(
          {
            moduleName: moduleData.moduleName,
            moduleId: moduleData.moduleId,
            keyMoments: newKeyMoments,
          },
          {
            onSuccess: ({ data }) => {
              queryClient.invalidateQueries({
                queryKey: ['moduleDataById', moduleId],
              });
              queryClient.setQueryData(['moduleDataById'], data);
              console.log('onSuccess changeKeyMomentsMutation');
            },
            onError: (error) => alert(error),
          },
        );
      }
    }
  };

  const OnBlurTextarea = (
    path: string,
    role: string,
    currentText: string | undefined,
    prevText: string,
  ) => {
    let newMoment;
    if (path === 'pathA') {
      newMoment =
        role === 'learner'
          ? { ...pathA, learnerText: editableTextPathALearner }
          : { ...pathA, characterText: editableTextPathACharacter };
    } else if (path === 'pathB') {
      newMoment =
        role === 'learner'
          ? { ...pathB, learnerText: editableTextPathBLearner }
          : { ...pathB, characterText: editableTextPathBCharacter };
    } else if (path === 'pathC') {
      newMoment =
        role === 'learner'
          ? { ...pathC, learnerText: editableTextPathCLearner }
          : { ...pathC, characterText: editableTextPathCCharacter };
    }

    if (currentText !== prevText) {
      updateLearnerAndCharacterTextInModuleScript(
        path,
        momentNumber,
        newMoment,
      );
    }
  };

  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!previewRef.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = previewRef.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item: { id: number; index: number }) => {
      onMoveMomentsInModuleScript(item.id, item.index);
    },
  });

  useEffect(() => {
    preview(getEmptyImage());
  }, []);

  const opacity = isDragging ? 0 : 1;
  drag(dragRef);
  drop(preview(previewRef));

  return (
    <MomentContainer style={{ opacity }} data-handler-id={handlerId}>
      <div ref={previewRef} style={{ opacity: !isDragging ? 0 : 1 }}>
        Drag item
      </div>
      {!isDragging ? (
        <>
          <AddNewMoment momentId={id} />
          <MomentHeaderBox>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <div ref={dragRef}>
                <DragHandleIcon sx={{ color: '#C8C8C8', cursor: 'pointer' }} />
              </div>
              <MomentHeader className="character">
                Moment {momentNumber + 1}
              </MomentHeader>
              <DeleteMoment momentId={id} />
            </Box>

            <Tooltip
              title="Select 3 moments which will be used to evaluate accomplishment of the learning objective: Manage emotions."
              placement="top-end"
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    background: '#0065FF',
                    color: '#FFF',
                    padding: '20px 30px',
                    fontSize: '15px',
                    borderRadius: '10px',
                    textAlign: 'center',
                    '& .MuiTooltip-arrow': {
                      color: '#0065FF',
                    },
                  },
                },
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    disabled={
                      new Set(module.keyMoments).size >= 3 && !isChecked
                    }
                    sx={{
                      color: 'rgba(48, 52, 58, 0.20)',
                      '&.Mui-checked': {
                        color: '#30343A',
                      },
                    }}
                  />
                }
                sx={{ fontSize: '12px', color: 'rgba(48, 52, 58, 0.50)' }}
                labelPlacement="start"
                label="Key learning moment"
              />
            </Tooltip>
          </MomentHeaderBox>
          <MomentCardsContainer>
            <MomentBox>
              <HeaderContainer>
                <MomentLabel>Learner</MomentLabel>
                <MomentLabel sx={{ color: '#A1A6AD' }}>(+1)</MomentLabel>
              </HeaderContainer>
              <LearnerText
                value={editableTextPathALearner}
                setValue={setEditableTextPathALearner}
                save={(val) =>
                  OnBlurTextarea('pathA', 'learner', pathA.learnerText, val)
                }
              />
            </MomentBox>
            <MomentBox>
              <HeaderContainer>
                <MomentLabel>Learner</MomentLabel>
                <MomentLabel sx={{ color: '#A1A6AD' }}>(-1)</MomentLabel>
              </HeaderContainer>
              <LearnerText
                value={editableTextPathBLearner}
                setValue={setEditableTextPathBLearner}
                save={(val) =>
                  OnBlurTextarea('pathB', 'learner', pathB.learnerText, val)
                }
              />
            </MomentBox>
            <MomentBox>
              <HeaderContainer>
                <MomentLabel>Learner</MomentLabel>
                <MomentLabel sx={{ color: '#A1A6AD' }}>(-2)</MomentLabel>
              </HeaderContainer>
              <LearnerText
                value={editableTextPathCLearner}
                setValue={setEditableTextPathCLearner}
                save={(val) =>
                  OnBlurTextarea('pathC', 'learner', pathC.learnerText, val)
                }
              />
            </MomentBox>
          </MomentCardsContainer>
          <MomentCardsContainer>
            <MomentBox>
              <HeaderContainer>
                <MomentLabel fontWeight="bold">
                  {module.characterName
                    ? module.characterName
                    : 'Character name'}
                </MomentLabel>
              </HeaderContainer>
              <CharacterText
                value={editableTextPathACharacter}
                setValue={setEditableTextPathACharacter}
                save={(val) =>
                  OnBlurTextarea('pathA', 'character', pathA.characterText, val)
                }
              />
            </MomentBox>
            <MomentBox>
              <HeaderContainer>
                <MomentLabel>
                  {module.characterName
                    ? module.characterName
                    : 'Character name'}
                </MomentLabel>
              </HeaderContainer>
              <CharacterText
                value={editableTextPathBCharacter}
                setValue={setEditableTextPathBCharacter}
                save={(val) =>
                  OnBlurTextarea('pathB', 'character', pathB.characterText, val)
                }
              />
            </MomentBox>
            <MomentBox>
              <HeaderContainer>
                <MomentLabel>
                  {module.characterName
                    ? module.characterName
                    : 'Character name'}
                </MomentLabel>
              </HeaderContainer>
              <CharacterText
                value={editableTextPathCCharacter}
                setValue={setEditableTextPathCCharacter}
                save={(val) =>
                  OnBlurTextarea('pathC', 'character', pathC.characterText, val)
                }
              />
            </MomentBox>
          </MomentCardsContainer>
        </>
      ) : null}
    </MomentContainer>
  );
}
