import React, { useRef, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { getModuleVoiceOver } from '../../api/apiClient';

const FeedbackContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  color: '#FFF',
});

const MainHeader = styled(Typography)({
  fontWeight: 700,
  fontSize: 'clamp(1.875rem, 1.6549rem + 0.939vw, 2.5rem)',
  letterSpacing: '-0.4px',
});

const RegularText = styled(Typography)({
  fontWeight: 450,
  fontSize: 'clamp(0.9375rem, 0.8275rem + 0.4695vw, 1.25rem)',
  letterSpacing: '-0.2px',
  color: '#D5D7D8',
  fontFamily: 'CircularStd-Medium',
});

const SecondaryText = styled(Typography)({
  fontSize: 'clamp(0.75rem, 0.684rem + 0.2817vw, 0.9375rem)',
  fontWeight: 450,
  color: '#A1A6AD',
  letterSpacing: '-0.15px',
  fontFamily: 'CircularStd-Light',
});

const FeedbackCardsContainer = styled(Box)({
  display: 'flex',
  gap: '28px',
  '@media (max-width:768px)': {
    flexDirection: 'column',
    gap: '10px',
  },
});

const FeedbackCard = styled(Box)({
  borderRadius: '15px',
  border: '3px solid rgba(255, 255, 255, 0.10)',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  padding: '25px',
  '@media (max-width:768px)': {
    gap: '10px',
    flexDirection: 'row',
  },
});

const FeedbackTextBlock = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
});

function FeedbackSummary({ moduleId }: { moduleId: string }) {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [voiceOverUrl, setVoiceOverUrl] = useState<string | null>(null);

  useEffect(() => {
    const loadVoiceOver = async () => {
      try {
        // Fetch the voiceover data
        const response = await getModuleVoiceOver({
          moduleId: moduleId, // Adjust moduleId as needed
          voiceOverKey: 'feedbackVO', // Adjust the voiceOverKey as needed
        });

        if (response.data.url) {
          // Set the voiceover URL
          setVoiceOverUrl(response.data.url);
        }
      } catch (error) {
        console.error('Error loading voiceover:', error);
      }
    };

    loadVoiceOver();
  }, [moduleId]);

  useEffect(() => {
    const audioElement = audioRef.current;

    if (audioElement && voiceOverUrl) {
      // Set the audio source using the voiceover URL
      audioElement.src = voiceOverUrl;

      // Autoplay the audio when the component mounts
      audioElement.play().catch((error) => {
        console.error('Audio playback error:', error);
      });
    }
  }, [voiceOverUrl]);
  return (
    <FeedbackContainer>
      <RegularText>How did you do?</RegularText>
      <MainHeader>Maintain Professional Boundaries</MainHeader>
      <FeedbackCardsContainer>
        <FeedbackCard>
          <CheckCircleIcon sx={{ color: '#20BF6F' }} />
          <FeedbackTextBlock>
            <RegularText>First opportunity</RegularText>
            <SecondaryText>
              Good work establishing an atmosphere of trust to help accelerate a
              culture of inclusion and belonging.
            </SecondaryText>
          </FeedbackTextBlock>
        </FeedbackCard>
        <FeedbackCard>
          <CancelIcon sx={{ color: '#CB4262' }} />
          <FeedbackTextBlock>
            <RegularText>Second opportunity</RegularText>
            <SecondaryText>
              Good work calling out subjective decision making to help dismantle
              barriers to economic opportunity.
            </SecondaryText>
          </FeedbackTextBlock>
        </FeedbackCard>
        <FeedbackCard>
          <CancelIcon sx={{ color: '#CB4262' }} />
          <FeedbackTextBlock>
            <RegularText>Third opportunity</RegularText>
            <SecondaryText>
              Good work establishing an atmosphere of trust to help accelerate a
              culture of inclusion and belonging.
            </SecondaryText>
          </FeedbackTextBlock>
        </FeedbackCard>
      </FeedbackCardsContainer>
    </FeedbackContainer>
  );
}

export default FeedbackSummary;
