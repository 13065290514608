import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, Drawer, IconButton, Button } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate, useParams } from 'react-router-dom';
import logoSm from '../../images/logo-sm-white.png';

interface Props {
  handleLogout: () => void;
  toggleDrawer: () => void;
  drawerOpen: boolean;
}
const Option = styled(Box)({
  padding: '20px',
  borderRadius: '5px',
  backgroundColor: 'rgb(190, 190, 190)',
  margin: '5px',
  color: 'black',
});

const CustomDrawer = styled(Drawer)({
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: 'min(100%, 400px)',
    marginTop: '10px',
    marginX: '20px',
    transform: 'translateX(20px)',
    padding: '25px',
    height: 'calc(100% - 40px)',
    borderRadius: '10px',
    backgroundColor: 'rgb(200, 200, 200)',
  },
});

const SidebarMenu = ({ handleLogout, toggleDrawer, drawerOpen }: Props) => {
  const navigate = useNavigate();
  const { moduleId } = useParams();
  return (
    <>
      <Box
        sx={{
          alignSelf: 'flex-start',
          zIndex: 4,
          position: 'absolute',
          top: '40px',
          left: '80px',
          width: 'fit-content',
          '&::before': {
            content: `url(${logoSm})`,
            left: '30px',
            top: '30px',
            position: 'fixed',
          },
        }}
      >
        <IconButton onClick={toggleDrawer}>
          <MenuIcon style={{ color: '#A1A6AD' }} />
        </IconButton>
      </Box>
      <CustomDrawer open={drawerOpen} sx={{ width: '%' }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton onClick={toggleDrawer}>
            <CloseIcon sx={{ color: 'black' }} />
          </IconButton>
        </Box>
        <Typography
          sx={{ color: 'black', fontWeight: 'bold', fontSize: '40px' }}
        >
          Menu
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
          }}
        >
          {moduleId && (
            <>
              <Option>
                <Button
                  sx={{ color: 'black', fontWeight: 'bold' }}
                  onClick={() => {
                    toggleDrawer();
                    navigate(`/module-list`);
                  }}
                >
                  Main menu
                </Button>
              </Option>
              <Option>
                <Button
                  sx={{ color: 'black', fontWeight: 'bold' }}
                  onClick={() => {
                    toggleDrawer();
                    // setStep(2);
                  }}
                >
                  Restart experience
                </Button>
              </Option>
            </>
          )}
          <Option>
            <Button sx={{ color: 'black', fontWeight: 'bold' }}>Support</Button>
          </Option>
          <Option>
            <Button
              sx={{ color: 'black', fontWeight: 'bold' }}
              onClick={handleLogout}
            >
              Sign out
            </Button>
          </Option>
        </Box>
      </CustomDrawer>
    </>
  );
};
export default SidebarMenu;
