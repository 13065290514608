import React, { useReducer } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import './aws-config';
import './axios-config';

import './App.css';

import moduleEditorReducer, {
  initialState,
  ModuleEditorContext,
} from './reducers/ModuleEditorReducer';
import ModuleList from './components/ModuleList';
import ModuleEditor from './components/ModuleEditor';
import Login from './components/Login';
import Player from './components/PlayerBrowserViewer';
import Viewer from './components/Viewer';
import ProtectedRoute from './components/ProtectedRoute';

function App() {
  const [state, dispatch] = useReducer(moduleEditorReducer, initialState);

  return (
    <ModuleEditorContext.Provider value={{ state, dispatch }}>
      <Routes>
        {/*<Route path="/sign-up" element={<SignUp />} />*/}
        <Route path="/login" element={<Login />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/modules" element={<ModuleList />} />
          <Route path="/module/:moduleId" element={<ModuleEditor />} />
          {/*<Route path="/viewer/:moduleId" element={<Viewer />} />*/}
          <Route path="/experience/:moduleId" element={<Player />} />
          <Route path="*" element={<Navigate to="/modules" replace />} />{' '}
          {/* "No Match" Route */}
          {/* Handle other routes */}
        </Route>
      </Routes>
    </ModuleEditorContext.Provider>
  );
}

export default App;
