import React, { useState } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { FullModule } from '../types';
import Thumbnail from '../images/thumbnail-module-list.png';
import ModuleMenuPopover from './ModuleList/ModuleMenuPopover';
import { useNavigate } from 'react-router-dom';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import EditModuleNameDialog from './ModuleList/EditModuleNameDialog';

type ModuleDisplayCardProps = {
  module: FullModule;
};

const ModuleBox = styled(Box)({
  display: 'flex',
  padding: '25px',
  borderRadius: '20px',
  background: '#FFFFFF',
  gap: '20px',
});

const ModuleMediaBox = styled(Box)({
  position: 'relative',
  height: 'fit-content',
});

const ModuleContentBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  flexGrow: 1,
});

const ModuleHeader = styled(Typography)({
  fontSize: '20px',
  fontFamily: 'CircularStd-Medium',
});

const ModuleDescription = styled(Typography)({
  fontSize: '12px',
  color: '#A1A6AD',
});

const ModuleMenuBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const { REACT_APP_PLAYER_URL } = process.env;
function ModuleDisplayCard({ module }: ModuleDisplayCardProps) {
  const [open, setOpen] = useState(false);
  const [isEditModuleNameDialogOpen, setIsEditModuleNameDialogOpen] =
    useState(false);
  const [selectedValue, setSelectedValue] = useState('');

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const navigate = useNavigate();

  const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value: string) => {
    setOpen(false);
    setSelectedValue(value);
    setAnchorEl(null);
  };

  return (
    <ModuleBox>
      <Box
        onClick={() => {
          navigate(`/experience/${module.moduleId}`);
        }}
        sx={{
          display: 'flex',
          gap: '20px',
          width: '100%',
          cursor: 'pointer',
        }}
      >
        <ModuleMediaBox>
          <img
            height="110"
            src={
              //todo uncomment when this is added to the module data
              // module.thumbnail_url
              // ? `${process.env.REACT_APP_ASSETS_URL}${module.thumbnail_url}`
              // :
              Thumbnail
            }
            alt="Module thumbnail"
          />
          <PlayCircleIcon
            style={{
              color: 'white',
              position: 'absolute',
              fontSize: '40px',
              left: '50%',
              top: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        </ModuleMediaBox>
        <ModuleContentBox>
          <ModuleHeader>{module.moduleName}</ModuleHeader>
          <ModuleDescription>{module.summary}</ModuleDescription>
        </ModuleContentBox>
      </Box>

      <ModuleMenuBox>
        <IconButton
          aria-label="settings"
          sx={{
            alignSelf: 'center',
            padding: '0',
            '&:hover': { backgroundColor: 'transparent' },
          }}
          onClick={handleClickOpen}
        >
          <MoreVertIcon />
        </IconButton>
      </ModuleMenuBox>
      <ModuleMenuPopover
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        module={module}
        anchorEl={anchorEl}
        showEditModuleNameModal={() => {
          setIsEditModuleNameDialogOpen(true);
          setOpen(false);
        }}
      />
      <EditModuleNameDialog
        open={isEditModuleNameDialogOpen}
        onClose={() => setIsEditModuleNameDialogOpen(false)}
        moduleName={module.moduleName}
        moduleId={module.moduleId}
      />
    </ModuleBox>
  );
}

export default ModuleDisplayCard;
