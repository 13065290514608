import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Typography,
  Box,
  Tabs,
  Tab,
  LinearProgress,
  Alert,
  AlertTitle,
} from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { TabPanelProps } from '../types';
import ChatContainer from './ChatContainer';
import Character from './Character';
import Script from './Script';
import Feedback from './Feedback';
import Details from './Details';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getModuleById } from '../api/apiClient';

const steps = [
  '1. Scene setup',
  '2. Building the Scene',
  '3. Script',
  '4. Feedback',
  '5. Details',
];

const RegularText = styled(Typography)({
  color: '#21272F',
  fontSize: '15px',
});

const TabsContainer = styled(Box)({
  borderBottom: '3px solid rgba(0, 0, 0, 0.05)',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '24px',
  position: 'sticky',
  zIndex: '2',
  top: '85px',
  background: '#F4F4F4',
});

const CustomTabs = styled(Tabs)({
  fontSize: '15px',
  '& .MuiTab-root': {
    textTransform: 'capitalize',
    '&:focus': {
      // Override the default focus styles here
      outline: 'none',
      boxShadow: 'none',
      backgroundColor: 'transparent', // Override any focus-related background color change
    },
  },
  '& .MuiTab-root.Mui-selected': {
    // Style for the selected tab
    color: '#21272F',
  },
  '& .MuiTabs-indicator': {
    // Style for the underline indicator
    display: 'none',
  },
});

const CustomTab = styled(Tab)({
  color: 'rgba(34, 39, 47, 0.20)',
});

const ModuleViewerLink = styled(Link)({
  marginLeft: '16px',
  alignSelf: 'center',
  textDecoration: 'none',
  fontFamily: 'CircularStd-Medium',
  color: '#0065FF',
});

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function ModuleEditor() {
  const [value, setValue] = useState(0);
  const { moduleId } = useParams();
  const queryClient = useQueryClient();
  const {
    data: moduleDataById,
    isLoading: isGetModuleDataByIdLoading,
    isError,
  } = useQuery({
    queryKey: ['moduleDataById', moduleId],
    queryFn: () => getModuleById(moduleId ? moduleId : ''),
    refetchOnWindowFocus: false,
    enabled: !!moduleId,
  });

  useEffect(() => {
    if (moduleDataById) {
      queryClient.setQueryData(['moduleDataById'], moduleDataById);
    }
  }, [moduleDataById]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  if (isError) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        Sorry, we couldn&apos;t load the module data :(
      </Alert>
    );
  }
  if (isGetModuleDataByIdLoading) {
    return <LinearProgress />;
  }

  if (!moduleDataById) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        No data :(
      </Alert>
    );
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <TabsContainer>
        <Box flexShrink={0} ml={2}>
          {' '}
          <RegularText>{moduleDataById.moduleName}</RegularText>
        </Box>
        <Box flexGrow={1} display="flex" justifyContent="center">
          {' '}
          <CustomTabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {steps.map((step, index) => (
              <CustomTab
                key={index}
                label={step}
                {...a11yProps(index)}
                disableRipple
              />
            ))}
            {/*todo temporarily hidden?*/}
            {/*<ModuleViewerLink to={`/viewer/${moduleId}`}>*/}
            {/*  View Module*/}
            {/*</ModuleViewerLink>*/}
          </CustomTabs>
        </Box>
      </TabsContainer>
      <CustomTabPanel value={value} index={0}>
        <ChatContainer setTab={setValue} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Character module={moduleDataById} setTab={setValue} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Script module={moduleDataById} setTab={() => setValue(value + 1)} />
      </CustomTabPanel>
      {moduleDataById.moduleScript && (
        <CustomTabPanel value={value} index={3}>
          <Feedback
            module={moduleDataById}
            keyMoments={moduleDataById.keyMoments}
            setTab={() => setValue(value + 1)}
          />
        </CustomTabPanel>
      )}
      <CustomTabPanel value={value} index={4}>
        <Details module={moduleDataById} />
      </CustomTabPanel>
    </Box>
  );
}

export default ModuleEditor;
