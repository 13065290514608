import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import HeadphonesIcon from '@mui/icons-material/Headphones';
import { Box, Typography, Link } from '@mui/material';
import levenshtein from 'js-levenshtein';
import Microphone from '../svg/PlayerSvg/Microphone';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import sound from '../../sounds/PositivePopPickUp.mp3';

const Container = styled(Box)({
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
  gap: '35px',
});

const MainHeader = styled(Typography)({
  fontWeight: 700,
  fontSize: 'clamp(1.875rem, 1.3248rem + 2.3474vw, 3.4375rem)',
  lineHeight: '55px',
  color: '#FFF',
});

const RegularText = styled(Typography)({
  fontWeight: 450,
  fontSize: '20px',
  letterSpacing: '-0.2px',
  color: '#D5D7D8',
  fontFamily: 'CircularStd-Regular',
  width: 'min(100%, 395px)',
});

const SecondaryText = styled(Typography)({
  fontSize: '15px',
  lineHeight: '18px',
  fontWeight: 450,
  color: '#A1A6AD',
  letterSpacing: '-0.15px',
  paddingY: '50xp',
  marginY: '10px',
  fontFamily: 'CircularStd-Light',
  width: 'min(100%, 200px)',
});

const VoiceOver = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '15px',
  border: '3px solid rgba(255, 255, 255, 0.10)',
  padding: '15px 40px 15px 30px',
  width: 'fit-content',
});

const VoiceOverIcon = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '50px',
  height: '50px',
  borderRadius: '50%',
  padding: '10px',
  transition: 'all 0.3s ease',
});

const VoiceOverText = styled(Typography)({
  marginLeft: '20px',
  fontSize: '20px',
  fontFamily: 'CircularStd-Medium',
  color: '#FFF',
});

const ContentBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '85px',
});

const MainBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '35px',
});

const InfoBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
  width: '40%',
});

const InfoItemBox = styled(Box)({
  display: 'flex',
  gap: '15px',
});

const InfoItemIcon = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '35px',
  height: '35px',
  borderRadius: '50%',
  padding: '10px',
  backgroundColor: 'rgba(255, 255, 255, 0.10)',
});

const targetString = 'I can speak and my words are powerful.';

const VoiceTest = ({ handleNext }: { handleNext: () => void }) => {
  const [status, setStatus] = useState<string>('');
  const audio = new Audio(sound);
  const playSound = async () => {
    await audio.play();
  };

  const { listening, browserSupportsSpeechRecognition, finalTranscript } =
    useSpeechRecognition();

  if (!browserSupportsSpeechRecognition) {
    alert(`Browser doesn't support speech recognition`);
  }

  const startListening = () =>
    SpeechRecognition.startListening({ language: 'en-US' });
  const stopListening = () => SpeechRecognition.stopListening();

  useEffect(() => {
    startListening();
  }, []);

  useEffect(() => {
    if (finalTranscript) {
      setStatus('');
      calculateLevenshteinDistance(finalTranscript);
    }
  }, [finalTranscript]);

  useEffect(() => {
    if (!listening) {
      startListening();
    }
  }, [listening]);

  const calculateLevenshteinDistance = async (text: string) => {
    const levDistance: number = levenshtein(targetString, text);
    stopListening();
    if (levDistance < 10) {
      await playSound();
      setStatus('matched');
      setTimeout(() => {
        handleNext();
      }, 1000);
    } else {
      setStatus('mismatch');
      setTimeout(() => {
        setStatus('');
        startListening();
      }, 1000);
    }
  };

  return (
    <Container>
      <MainHeader>Voice test</MainHeader>
      <ContentBox>
        <MainBox>
          <RegularText>
            This is a voice activated experience. Read the text below out loud,
            slowly, and clearly.
          </RegularText>
          <VoiceOver>
            <VoiceOverIcon
              sx={{
                backgroundColor: !status
                  ? 'rgba(255, 255, 255, 0.10)'
                  : status === 'matched'
                    ? 'rgba(0, 101, 255, 1)'
                    : 'rgba(226, 29, 29, 1)',
              }}
            >
              {status === 'matched' ? (
                <CheckIcon sx={{ color: 'white', fontSize: '30px' }} />
              ) : null}
              {status === 'mismatch' ? (
                <CloseIcon sx={{ color: 'white', fontSize: '30px' }} />
              ) : null}
              {!status && <Microphone color={'#20BF6F'} isAnimation />}
            </VoiceOverIcon>
            <VoiceOverText>
              &quot;I can speak and my words are powerful.&quot;
            </VoiceOverText>
          </VoiceOver>
        </MainBox>
        <InfoBox>
          <InfoItemBox>
            <InfoItemIcon>
              <ThumbUpOffAltIcon sx={{ color: '#A1A6AD', fontSize: '20px' }} />
            </InfoItemIcon>
            <SecondaryText>
              Please accept the request to activate your device&apos;s
              microphone.{' '}
              <Link
                href="https://support.google.com/chrome/answer/2693767"
                underline="always"
                target="_blank"
                sx={{ color: '#A1A6AD', textDecorationColor: '#A1A6AD' }}
              >
                Manually activate microphone.
              </Link>
            </SecondaryText>
          </InfoItemBox>
          <InfoItemBox>
            <InfoItemIcon>
              <HeadphonesIcon sx={{ color: '#A1A6AD', fontSize: '20px' }} />
            </InfoItemIcon>
            <SecondaryText>We strongly recommend headphones</SecondaryText>
          </InfoItemBox>
        </InfoBox>
      </ContentBox>
    </Container>
  );
};

export default VoiceTest;
