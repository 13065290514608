import React, { useEffect } from 'react';
import VoiceTest from '../components/PlayerComponents/VoiceTest';
import LearningObjective from '../components/PlayerComponents/LearningObjective';
import CharacterIntro from '../components/PlayerComponents/CharacterIntro';
import Confidence from '../components/PlayerComponents/Confidence';
import Results from '../components/PlayerComponents/Results';
import ExperienceViewer from '../components/PlayerComponents/ExperienceViewer';
import Layout from '../components/PlayerComponents/Layout';
import ExperienceIntro from '../components/PlayerComponents/ExperienceIntro';
import Intro from '../components/PlayerComponents/Intro';
import Feedback from '../components/PlayerComponents/Feedback';
import Recommendation from '../components/PlayerComponents/Recommendation';
import FeedbackOptions from '../components/PlayerComponents/FeedbackOptions';
import CameraMicrophonePermission from '../components/PlayerComponents/CameraMicrophonePermission';
import { useParams } from 'react-router-dom';

import { FullModule } from '../types';
import { getModuleById } from '../api/apiClient';

function BrowserViewer() {
  const { moduleId } = useParams();
  const [activeStep, setActiveStep] = React.useState(0);
  // const [pathA, setPathA] = React.useState(null);
  // const [pathB, setPathB] = React.useState(null);
  // const [pathC, setPathC] = React.useState(null);
  const [summary, setSummary] = React.useState('');
  const [learningObjective, setLearningObjective] = React.useState('');
  const [communicationStyleA, setCommunicationStyleA] = React.useState('');
  const [communicationStyleB, setCommunicationStyleB] = React.useState('');
  const [communicationStyleC, setCommunicationStyleC] = React.useState('');
  const [moduleName, setModuleName] = React.useState('');
  const [characterName, setCharacterName] = React.useState('');
  // const [keyMoments, setKeyMoments] = React.useState<Set<number>>(new Set());
  // const [moduleScript, setModuleScript] = React.useState<Moment[] | null>(null);

  const retrieveModuleData = (module: FullModule): void => {
    if (module.learningObjective) {
      setLearningObjective(module.learningObjective);
    }
    if (module.communicationStyleA) {
      setCommunicationStyleA(module.communicationStyleA);
    }
    if (module.communicationStyleB) {
      setCommunicationStyleB(module.communicationStyleB);
    }
    if (module.communicationStyleC) {
      setCommunicationStyleC(module.communicationStyleC);
    }
    if (module.characterName) {
      setCharacterName(module.characterName);
    }
    if (module.summary) {
      setSummary(module.summary);
    }
    if (module.moduleName) {
      setModuleName(module.moduleName);
    }
    if (module.keyMoments) {
      console.log(module.keyMoments);
      // setKeyMoments(keyMoments);
    }
    if (module.moduleScript) {
      console.log(module.moduleScript);
      // setModuleScript(module.moduleScript);
      // setPathA(module.moduleScript.pathA);
      // setPathB(module.moduleScript.pathB);
      // setPathC(module.moduleScript.pathC);
    }
  };

  const fetchModuleById = async (moduleId: string) => {
    await getModuleById(moduleId)
      .then((module: FullModule) => {
        retrieveModuleData(module);
      })
      .catch((err) => console.error('Failed to fetch module list:', err));
  };

  useEffect(() => {
    if (moduleId) {
      fetchModuleById(moduleId);
    }
  }, []);

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const wrapLayout = (
    step: React.ReactElement,
    isNextButtonVisible: boolean,
  ) => (
    <Layout
      activeStep={activeStep}
      handleBack={handleBack}
      handleNext={handleNext}
      isNextButtonVisible={isNextButtonVisible}
    >
      {step}
    </Layout>
  );

  const steps = [
    wrapLayout(<CameraMicrophonePermission />, true),
    wrapLayout(<VoiceTest handleNext={handleNext} />, false),
    wrapLayout(
      <Intro moduleId={moduleId ? moduleId : ''} moduleName={moduleName} />,
      true,
    ),
    wrapLayout(
      <LearningObjective
        learningObjective={learningObjective}
        summary={summary}
        moduleId={moduleId ? moduleId : ''}
      />,
      true,
    ),
    wrapLayout(
      <Confidence
        promptString={'How confident are you in'}
        handleNext={handleNext}
      />,
      false,
    ),
    wrapLayout(
      <ExperienceIntro
        moduleId={moduleId ? moduleId : ''}
        handleNext={handleNext}
      />,
      true,
    ),
    wrapLayout(
      <CharacterIntro
        moduleId={moduleId ? moduleId : ''}
        characterName={characterName}
        communicationStyleA={communicationStyleA}
        communicationStyleB={communicationStyleB}
        communicationStyleC={communicationStyleC}
      />,
      true,
    ),
    <div key={'key'} />,
    // <ExperienceViewer
    //   key={moduleName}
    //   handleNext={() => setActiveStep(8)}
    //   moduleId={moduleId ? moduleId : ''}
    // />,
    wrapLayout(<Feedback moduleId={moduleId ? moduleId : ''} />, true),
    wrapLayout(
      <Confidence
        promptString={
          'Now that you have completed the experience, how confident are you in'
        }
        handleNext={handleNext}
      />,
      false,
    ),
    wrapLayout(<Recommendation handleNext={handleNext} />, false),
    wrapLayout(
      <Confidence
        promptString={
          'How likely are you to recommend this experience to others who need to learn the same skills?'
        }
        handleNext={handleNext}
      />,
      false,
    ),
    wrapLayout(<FeedbackOptions handleNext={handleNext} />, false),
    wrapLayout(<Results />, false),
  ];

  // return steps[activeStep];
  return (
    <div>
      {steps[activeStep]}
      <div style={{ display: activeStep === 7 ? 'block' : 'none' }}>
        <ExperienceViewer
          triggerStartVideo={activeStep === 7}
          key={moduleName}
          handleNext={() => setActiveStep(8)}
          moduleId={moduleId ? moduleId : ''}
        />
      </div>
    </div>
  );
}

export default BrowserViewer;
