import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { Paper } from '@mui/material';

interface Props {
  children: ReactNode;
}

const Foreground = styled(Paper)({
  width: 'min(100%, 1094px)',
  marginTop: '30px',
  borderRadius: '25px',
  boxSizing: 'border-box',
  padding: 'min(10%, 80px)',
  backgroundColor: 'rgba(255, 255, 255, 0.15)',
  backdropFilter: 'blur(40px)',
  flex: 1,
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  '@media (max-width:375px)': {
    padding: '0',
    backgroundColor: 'inherit',
    backdropFilter: 'blur(0)',
    backgroundImage: 'none',
    boxShadow: 'none',
    justifyContent: 'flex-start',
    borderRadius: '0',
  },
});

const ForegroundWrapper = ({ children }: Props) => (
  <Foreground>{children}</Foreground>
);

export default ForegroundWrapper;
