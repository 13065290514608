import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, Button } from '@mui/material';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '35px',
  color: '#FFF',
});

const QuestionText = styled(Typography)({
  fontSize: 'clamp(1.5625rem, 1.4525rem + 0.4695vw, 1.875rem)',
  lineHeight: '40px',
  width: 'min(100%, 500px)',
  '@media (max-width:375px)': {
    lineHeight: '30px',
  },
});

const FeedbackOptionsBlock = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '10px',
  '@media (max-width:375px)': {
    flexDirection: 'column',
  },
});

const FeedbackOption = styled(Button)({
  fontSize: '20px',
  color: '#D5D7D8',
  fontFamily: 'CircularStd-Medium',
  padding: '15px 40px',
  borderRadius: '15px',
  background: 'rgba(255, 255, 255, 0.10)',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: '#2875EA',
    color: '#FFFFFF',
  },
  '@media (max-width:375px)': {
    width: '100%',
  },
});

const FeedbackOptions = ({ handleNext }: { handleNext: () => void }) => {
  const handleOptionSelect = (option: string) => {
    handleNext();
  };

  return (
    <Container>
      <QuestionText>
        What makes you hesitant to recommend this virtual learning experience
        for use by others?
      </QuestionText>
      <FeedbackOptionsBlock>
        {[
          'Content',
          'Logistics & distribution',
          'Technical issues',
          'Not engaging',
          'Physical discomfort',
          'Other',
        ].map((option) => (
          <FeedbackOption
            key={option}
            onClick={() => handleOptionSelect(option)}
          >
            {option}
          </FeedbackOption>
        ))}
      </FeedbackOptionsBlock>
    </Container>
  );
};

export default FeedbackOptions;
