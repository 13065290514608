import React from 'react';
import { Typography, Fade, Modal, Link, Box } from '@mui/material';
import {
  CustomButton,
  ModalBox,
} from '../StyledComponents/SampleStyledComponents';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

interface Props {
  onClose: () => void;
  onSubmit: () => void;
  open: boolean;
  unfinishedStepsList: string[];
}

function ModuleUnfinishedModal({
  open,
  onClose,
  onSubmit,
  unfinishedStepsList,
}: Props) {
  return (
    <Modal open={open} onClose={onClose} closeAfterTransition>
      <Fade in={open}>
        <ModalBox sx={{ width: '310px', alignItems: 'flex-start' }}>
          <Box
            sx={{
              borderRadius: '50%',
              backgroundColor: '#E21D1D',
              width: '40px',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <PriorityHighIcon sx={{ color: 'white' }} />
          </Box>
          <Typography
            sx={{
              fontSize: '25px',
              letterSpacing: '-0.75px',
              fontFamily: 'CircularStd-Medium',
              lineHeight: '25px',
            }}
          >
            Before we can build...
          </Typography>
          <Typography
            sx={{
              letterSpacing: '-0.45px',
              fontFamily: 'CircularStd-Regular',
              lineHeight: '22px',
            }}
          >
            There are 1 or more unfinished sections that are required before we
            can start building your module.
          </Typography>
          <ul style={{ color: '#E21D1D', fontWeight: 'bold' }}>
            {/*todo make a list from props*/}
            {unfinishedStepsList.map((unfinishedStepName: string) => {
              return <li key={unfinishedStepName}>{unfinishedStepName}</li>;
            })}
          </ul>
          <CustomButton
            variant={'contained'}
            onClick={() => {
              onSubmit();
            }}
          >
            Exit & fix required sections
          </CustomButton>
        </ModalBox>
      </Fade>
    </Modal>
  );
}

export default ModuleUnfinishedModal;
