import React, { ReactNode, useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import {
  CustomTextField,
  SettingBox,
  SettingCounter,
  SettingHeader,
  SettingLabelBox,
  SettingsContainer,
} from '../StyledComponents/SampleStyledComponents';
import { useOutsideClick } from '../../hooks/useOutsideClick';
interface Props {
  name: string;
  description: string;
  icon: ReactNode;
  value: string;
  handleChange: (e: any) => void;
  handleBlur: (value: string) => void;
  setEditing: (val: boolean) => void;
}

const FeedbackItem = ({
  name,
  description,
  icon,
  value,
  handleChange,
  handleBlur,
  setEditing,
}: Props) => {
  const [isEditingActive, setIsEditingActive] = useState(false);
  const [feedbackBody, setFeedbackBody] = useState(value);

  const ref = useOutsideClick(() => {
    handleBlur(feedbackBody);
    setIsEditingActive(false);
    setEditing(false);
  });
  return (
    <Box
      sx={{
        display: 'flex',
        gap: '15px',
        padding: '25px',
        borderRadius: '15px',
        background: 'rgba(0, 0, 0, 0.05)',
        width: '100%',
        height: 'fit-content',
      }}
    >
      <Box>{icon}</Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          width: '100%',
        }}
      >
        {isEditingActive ? (
          <div ref={ref}>
            <SettingsContainer
              sx={{ width: '100%', padding: '0', background: 'none' }}
            >
              <SettingBox>
                <SettingLabelBox>
                  <SettingHeader>Feedback</SettingHeader>
                  <SettingCounter>{feedbackBody?.length}/60</SettingCounter>
                </SettingLabelBox>
                <CustomTextField
                  placeholder={'Module description'}
                  id="outlined-multiline-static"
                  inputProps={{ maxLength: 60 }}
                  multiline
                  rows={6}
                  value={value}
                  onChange={(e) => {
                    handleChange(e);
                    setFeedbackBody(e.target.value);
                  }}
                  onBlur={(e) => handleBlur(e.target.value)}
                />
              </SettingBox>
            </SettingsContainer>
          </div>
        ) : (
          <>
            <Typography sx={{ lineHeight: '22px' }}>{description}</Typography>
          </>
        )}
      </Box>
      {!isEditingActive && (
        <Box>
          <IconButton
            onClick={() => {
              setIsEditingActive(!isEditingActive);
              setEditing(true);
            }}
            aria-label="edit"
            size="small"
          >
            <CreateIcon sx={{ color: '#C8C8C8' }} fontSize={'small'} />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default FeedbackItem;
