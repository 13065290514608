import React, { useState, useEffect } from 'react';
import { Typography, Box, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import CommunicationStyles from './Script/CommunicationStyles';
import GenerateScriptButtons from './Script/GenerateScriptButtons';
import Moment0 from './Script/Moment0';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  CommunicationStyles as CommunicationStylesType,
  FullModule,
} from '../types';
import { updateCommunicationStyles } from '../api/apiClient';
import { useParams } from 'react-router-dom';
import ScriptFooter from './Script/ScriptFooter';
import ScriptMoments from './Script/ScriptMoments';

const ScriptLayout = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '25px',
  background: '#FFF',
  gap: '30px',
  padding: '50px 35px',
  marginBottom: '134px',
});

const ScriptHeader = styled(Typography)({
  fontSize: '30px',
});

interface Props {
  module: FullModule;
  setTab: () => void;
}

function Script({ module, setTab }: Props) {
  const [selectedCommunicationStyleA, setSelectedCommunicationStyleA] =
    useState(
      module.communicationStyleA ? module.communicationStyleA : 'Empathetic',
    );
  const [selectedCommunicationStyleB, setSelectedCommunicationStyleB] =
    useState(module.communicationStyleB ? module.communicationStyleB : 'Rude');
  const [selectedCommunicationStyleC, setSelectedCommunicationStyleC] =
    useState(
      module.communicationStyleC ? module.communicationStyleC : 'Hostile',
    );
  const { moduleId } = useParams();
  const queryClient = useQueryClient();

  const {
    mutate: updateCommunicationStylesMutation,
    isPending: isUpdateCommunicationStylesLoading,
  } = useMutation({
    mutationFn: (communicationStyles: CommunicationStylesType) =>
      updateCommunicationStyles(communicationStyles),
  });

  useEffect(() => {
    if (!module.communicationStyleA) {
      updateCommunicationStylesMutation({
        moduleId: moduleId ? moduleId : '',
        communicationStyleA: selectedCommunicationStyleA,
        communicationStyleB: selectedCommunicationStyleB,
        communicationStyleC: selectedCommunicationStyleC,
      });
    }
  }, []);

  //for scroll effects
  const [isBorderVisible, setIsBorderVisible] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
  }, []);
  const listenToScroll = () => {
    const heightToHideFrom = 100;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll > heightToHideFrom) {
      if (!isBorderVisible) {
        setIsBorderVisible(true);
      }
    } else {
      setIsBorderVisible(false);
    }
  };

  const OnSelectCommunicationStyle = (
    val: string,
    communicationStyleType: string,
  ) => {
    switch (communicationStyleType) {
      case 'communicationStyleA':
        setSelectedCommunicationStyleA(val);
        break;
      case 'communicationStyleB':
        setSelectedCommunicationStyleB(val);
        break;
      case 'communicationStyleC':
        setSelectedCommunicationStyleC(val);
        break;
      default:
        console.log('communicationStyle unknown');
    }
    updateCommunicationStylesMutation(
      {
        moduleId: moduleId ? moduleId : '',
        [communicationStyleType]: val,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ['moduleDataById', moduleId],
          });
          console.log('onSuccess updateCommunicationStylesMutation');
        },
      },
    );
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <CommunicationStyles
        communicationStyleA={selectedCommunicationStyleA}
        communicationStyleB={selectedCommunicationStyleB}
        communicationStyleC={selectedCommunicationStyleC}
        handleSelectCommunicationStyle={(val, communicationStyleType) =>
          OnSelectCommunicationStyle(val, communicationStyleType)
        }
        isModuleScriptCreated={!!module.moduleScript}
        isBorderVisible={isBorderVisible}
        isLoading={isUpdateCommunicationStylesLoading}
      />
      {!module.moduleScript && (
        <GenerateScriptButtons
          isModuleScriptCreated={!!module.moduleScript}
          communicationStyleA={selectedCommunicationStyleA}
          communicationStyleB={selectedCommunicationStyleB}
          communicationStyleC={selectedCommunicationStyleC}
        />
      )}
      {module.moduleScript && (
        <ScriptLayout>
          <ScriptHeader>Script</ScriptHeader>
          <Divider
            variant="middle"
            sx={{ color: '#E2E2E2', width: '100%', height: '1px' }}
          />
          <Moment0
            content={module.moduleScript ? module.moduleScript.moment0 : ''}
            characterName={module.characterName}
          />
          <DndProvider backend={HTML5Backend}>
            <ScriptMoments module={module} />
          </DndProvider>
        </ScriptLayout>
      )}
      {module.moduleScript && (
        <ScriptFooter
          keyMoments={module.keyMoments}
          setTab={setTab}
          isModuleScriptCreated={!!module.moduleScript}
        />
      )}
    </Box>
  );
}

export default Script;
