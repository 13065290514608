import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '35px',
});

const MainHeader = styled(Typography)({
  fontWeight: 700,
  fontSize: 'clamp(1.875rem, 1.3248rem + 2.3474vw, 3.4375rem)',
  width: 'min(100%, 500px)',
  lineHeight: '55px',
  color: '#FFF',
});

const SecondaryHeader = styled(Typography)({
  fontSize: '20px',
  letterSpacing: '-0.2px',
  color: '#D5D7D8',
  width: 'min(100%, 400px)',
});

const CameraMicrophonePermission = () => {
  const getCameraPermission = () => {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: true })
        .then((stream) => {
          stream.getTracks().forEach((x) => x.stop());
        })
        .catch((err) => console.log(err));
    } else {
      console.log('getUserMedia not supported on your browser!');
    }
  };

  useEffect(() => {
    getCameraPermission();
  }, []);

  return (
    <Container>
      <MainHeader>Enable microphone and camera</MainHeader>
      <SecondaryHeader>
        For best experience please enable your microphone and camera when
        prompted.
      </SecondaryHeader>
    </Container>
  );
};

export default CameraMicrophonePermission;
