import { styled } from '@mui/material/styles';
import { Box, Button, TextField } from '@mui/material';

export const Container = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  gap: '20px',
});

export const SampleContainer = styled(Box)({
  position: 'relative',
  height: 'fit-content',
  '&::before': {
    content: "'Sample'",
    position: 'absolute',
    bottom: '-20px',
    padding: '10px',
    borderRadius: '40px',
    background: 'rgba(255, 255, 255, 0.95)',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: '0',
    right: '0',
    textAlign: 'center',
    width: '87px',
    fontSize: '15px',
    boxSizing: 'border-box',
  },
});

export const SettingsContainer = styled(Box)({
  borderRadius: '20px',
  background: 'rgba(0, 0, 0, 0.05)',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  padding: '30px',
  width: 'min(40%, 350px)',
  boxSizing: 'border-box',
  height: 'fit-content',
});

export const SettingBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
});

export const SettingLabelBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});
export const SettingHeader = styled(Box)({
  fontWeight: 'bold',
  color: '#5C5F64',
  marginBottom: '10px',
});
export const SettingCounter = styled(Box)({
  color: '#818893',
  fontSize: '12px',
});

export const CustomTextField = styled(TextField)({
  borderRadius: '5px',
  border: '1px solid #818893',
  background: '#FFF',
  textarea: {
    color: '#818893',
    fontFamily: 'CircularStd-Light',
    lineHeight: '20px',
  },
});

export const CustomButton = styled(Button)({
  fontFamily: 'CircularStd-Medium',
  fontSize: '15px',
  lineHeight: '20px',
  borderRadius: '50px',
  padding: '12px 25px',
  textTransform: 'none',
  boxShadow: 'none',
});

export const CustomTextButton = styled(Button)({
  fontSize: '12px',
  lineHeight: '12px',
  padding: '0',
  color: 'rgba(0, 101, 255, 1)',
  textTransform: 'none',
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

export const ModalBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '20px',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  background: '#FFFFFF',
  borderRadius: '20px',
  boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.10)',
  padding: '45px 30px',
});
