import React, { useEffect, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { Button, CircularProgress, Box } from '@mui/material';
import './ExperienceViewer.css';
import Webcam from './Webcam';
import { getModuleListeningLoop, getModuleVideo } from '../../api/apiClient';
import BackgroundWrapper from './BackgroundWrapper';
import ExperienceViewerDialog from './ExperienceViewerDialog';

export const FIRST_OPTION = 'A';
export const SECOND_OPTION = 'B';
export const THIRD_OPTION = 'C';

const StyledVideo = styled('video')({
  width: '100vw',
  height: '100vh',
  position: 'fixed',
  objectFit: 'cover',
  top: 0,
  left: 0,
  zIndex: 1,
  transition: 'all 0.3s ease-in-out',
});

const WebcamContainer = styled(Box)({
  position: 'absolute',
  top: '5%',
  right: '5%',
  width: '172px',
  height: '172px',
  overflow: 'hidden',
  zIndex: 2,
  borderRadius: '15px',
  '-webkit-transform': 'scaleX(-1)',
  transform: 'scaleX(-1)',
});
export const LayoutLoader = styled(Box)({
  position: 'absolute',
  background: 'black',
  top: '0px',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 2,
});
interface Step {
  A: string;
  B: string;
  C: string;
  choice: string | null;
}
interface Steps {
  [key: number]: Step;
}
function ExperienceViewer({
  triggerStartVideo,
  handleNext,
  moduleId,
}: {
  handleNext: () => void;
  moduleId: string;
  triggerStartVideo: boolean;
}) {
  const [momentNumber, setMomentNumber] = useState(0);
  const [currentVideo, setCurrentVideo] = useState('');
  const [currentListeningLoop, setCurrentListeningLoop] = useState('');
  const [isListeningLoopVisible, setIsListeningLoopVisible] = useState(false);
  const [isListeningLoopVideoLoading, setIsListeningLoopVideoLoading] =
    useState(true);
  const [selectedBranch, setSelectedBranch] = useState<string | null>(null);
  const initialVideoRef = useRef<HTMLVideoElement | null>(null);
  const listeningLoopRef = useRef<HTMLVideoElement | null>(null);
  const [isCurrentVideoEnded, setIsCurrentVideoEnded] = useState(false);
  const [isConversationFinished, setIsConversationFinished] = useState(false);

  const [steps, setSteps] = useState<Steps>({});
  const fetchModuleVideo = async (step: number, option: string) => {
    const videoId = step === 0 ? String(step) : step + '_' + option;
    return await getModuleVideo({ moduleId: moduleId, videoId: videoId })
      .then((resp: any) => {
        if (videoId === '0') {
          setCurrentVideo(resp.data.url);
        }
        return {
          url: resp.data.url,
          step: step,
          option: option,
        };
      })
      .catch((err: any) => {
        console.error('Failed to fetch module video:', err);
        alert(`Failed to fetch module video: ${err.message}`);
        return null;
      });
  };

  const fetchModuleListeningLoop = async () => {
    await getModuleListeningLoop({ moduleId: moduleId })
      .then((resp: any) => {
        setCurrentListeningLoop(resp.data.url);
        setIsListeningLoopVideoLoading(false);
      })
      .catch((err: any) => {
        console.error('Failed to fetch module listening loop:', err);
        alert(`Failed to fetch module listening loop: ${err.message}`);
      });
  };

  useEffect(() => {
    fetchModuleListeningLoop();
    fetchModuleVideo(0, '');
  }, []);

  interface ModuleVideoResponse {
    url: string;
    step: number;
    option: string;
  }

  const loadNextStep = () => {
    const promises: Promise<ModuleVideoResponse | null>[] = [];
    const nextMomentNumber = momentNumber + 1;
    [FIRST_OPTION, SECOND_OPTION, THIRD_OPTION].map((option: string) => {
      promises.push(fetchModuleVideo(nextMomentNumber, option));
    });

    Promise.all(promises).then((values) => {
      const newState = { ...steps };

      values.map((value: ModuleVideoResponse | null) => {
        if (value) {
          newState[nextMomentNumber] = {
            ...newState[nextMomentNumber],
            [value.option]: value.url,
          };
        }
      });

      setSteps(newState);
    });
  };

  useEffect(() => {
    const videoElement = initialVideoRef.current;
    if (triggerStartVideo) {
      videoElement?.play();
    }
  }, [triggerStartVideo]);

  const handleVideoEnd = () => {
    setIsCurrentVideoEnded(true);
    const listeningLoopElement: HTMLVideoElement | null =
      listeningLoopRef.current;
    if (momentNumber >= 10) {
      handleNext();
    } else {
      listeningLoopElement?.play();
    }
  };
  const handleVideoPause = () => {
    setIsCurrentVideoEnded(true);
  };
  const handleVideoPlay = () => {
    setIsCurrentVideoEnded(false);
    dialogRef.current?.stopListening();
    loadNextStep();
  };

  const handleTimeUpdate = (videoElement: HTMLVideoElement) => {
    if (videoElement.duration - videoElement.currentTime <= 5) {
      if (!isConversationFinished) {
        dialogRef.current?.setIsPathContainerVisible(true);
        dialogRef.current?.startListening();
        setIsListeningLoopVisible(true);
      }
    }
  };

  const handleListeningLoopPlay = () => {
    dialogRef.current?.startListening();
  };

  const handleListeningLoopEnd = () => {
    dialogRef.current?.stopListening();
  };

  const isCurrentVideo = (step: number, option: string): boolean => {
    return step === momentNumber && selectedBranch === option;
  };

  useEffect(() => {
    if (momentNumber === 1) {
      initialVideoRef.current?.pause();
    } else {
      itemsEls.current[momentNumber - 1]?.A?.pause();
      itemsEls.current[momentNumber - 1]?.B?.pause();
      itemsEls.current[momentNumber - 1]?.C?.pause();
    }
    switch (selectedBranch) {
      case FIRST_OPTION:
        itemsEls.current[momentNumber].A?.play();
        break;
      case SECOND_OPTION:
        itemsEls.current[momentNumber].B?.play();
        break;
      case THIRD_OPTION:
        itemsEls.current[momentNumber].C?.play();
        break;
    }

    // TODO remove from steps previous step (optional action)
  }, [momentNumber]);

  interface vidRefI {
    [key: string]: HTMLVideoElement | null;
  }
  interface ElRefI {
    [key: number]: vidRefI;
  }
  const itemsEls = useRef<ElRefI>({});

  const renderVideos = () => {
    return Object.getOwnPropertyNames(steps).map((stepMomentNumber) => {
      const i = Number(stepMomentNumber);
      itemsEls.current[i] = { A: null, B: null, C: null };
      return (
        <>
          <StyledVideo
            preload="auto"
            autoPlay={isCurrentVideo(i, FIRST_OPTION)}
            ref={(element: HTMLVideoElement) => {
              itemsEls.current[i].A = element;
            }}
            key={`${i}_keyA`}
            onEnded={handleVideoEnd}
            onPlay={handleVideoPlay}
            onPause={handleVideoPause}
            onTimeUpdate={(event) => handleTimeUpdate(event.currentTarget)}
            style={{
              zIndex: 2,
              opacity:
                isCurrentVideo(i, FIRST_OPTION) && !isCurrentVideoEnded ? 1 : 0,
            }}
          >
            <source src={steps[i].A} type="video/mp4" />
          </StyledVideo>
          <StyledVideo
            preload="auto"
            autoPlay={isCurrentVideo(i, SECOND_OPTION)}
            ref={(element: HTMLVideoElement) => {
              itemsEls.current[i].B = element;
            }}
            key={`${i}_keyB`}
            onEnded={handleVideoEnd}
            onPlay={handleVideoPlay}
            onPause={handleVideoPause}
            onTimeUpdate={(event) => handleTimeUpdate(event.currentTarget)}
            style={{
              zIndex: 2,
              opacity:
                isCurrentVideo(i, SECOND_OPTION) && !isCurrentVideoEnded
                  ? 1
                  : 0,
            }}
          >
            <source src={steps[i].B} type="video/mp4" />
          </StyledVideo>
          <StyledVideo
            preload="auto"
            autoPlay={isCurrentVideo(i, THIRD_OPTION)}
            ref={(element: HTMLVideoElement) => {
              itemsEls.current[i].C = element;
            }}
            key={`${i}_keyC`}
            onEnded={handleVideoEnd}
            onPlay={handleVideoPlay}
            onPause={handleVideoPause}
            onTimeUpdate={(event) => handleTimeUpdate(event.currentTarget)}
            style={{
              zIndex: 2,
              opacity:
                isCurrentVideo(i, THIRD_OPTION) && !isCurrentVideoEnded ? 1 : 0,
            }}
          >
            <source src={steps[i].C} type="video/mp4" />
          </StyledVideo>
        </>
      );
    });
  };

  interface DialogRefI {
    startListening(): void;
    stopListening(): void;
    setIsPathContainerVisible(value: boolean): void;
  }

  const dialogRef = useRef<DialogRefI>(null);

  return (
    <BackgroundWrapper>
      <StyledVideo
        ref={initialVideoRef}
        autoPlay={triggerStartVideo}
        preload="auto"
        key={`${currentVideo}_key1`}
        onEnded={handleVideoEnd}
        onPlay={handleVideoPlay}
        onPause={handleVideoPause}
        onTimeUpdate={(event) => handleTimeUpdate(event.currentTarget)}
        style={{
          zIndex: 2,
          opacity: momentNumber === 0 && !isCurrentVideoEnded ? 1 : 0,
        }}
      >
        <source src={currentVideo} type="video/mp4" />
      </StyledVideo>
      {renderVideos()}
      <StyledVideo
        ref={listeningLoopRef}
        preload="auto"
        onPlay={handleListeningLoopPlay}
        onEnded={handleListeningLoopEnd}
        key={`${currentListeningLoop}_key2`}
        style={{
          zIndex: 1,
          opacity: isListeningLoopVisible ? 1 : 0,
        }}
        loop
      >
        <source src={currentListeningLoop} type="video/mp4" />
      </StyledVideo>
      {Object.keys(steps).length ? (
        <ExperienceViewerDialog
          ref={dialogRef}
          moduleId={moduleId}
          isListeningLoopVisible={isListeningLoopVisible}
          onConversationFinished={() => {
            setIsConversationFinished(true);
          }}
          onBranchSelected={(branch: string | null): void => {
            setSelectedBranch(null);
            if (null === branch) {
              return;
            }

            setSelectedBranch(branch);
            setMomentNumber((prevMoment: number) => prevMoment + 1);
          }}
        />
      ) : null}
      <WebcamContainer>
        <Webcam />
      </WebcamContainer>
      {isListeningLoopVideoLoading && (
        <LayoutLoader>
          <CircularProgress />
        </LayoutLoader>
      )}
    </BackgroundWrapper>
  );
}

export default ExperienceViewer;
