import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import { Button, CircularProgress, Box } from '@mui/material';
import levenshtein from 'js-levenshtein';
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition';

import { FullModule, Moment } from '../../types';

import './ExperienceViewer.css';

import { getModuleById } from '../../api/apiClient';
import Microphone from '../svg/PlayerSvg/Microphone';

import { FIRST_OPTION, SECOND_OPTION, THIRD_OPTION } from './ExperienceViewer';
import CloseIcon from '@mui/icons-material/Close';

const DialogButton = styled(Button)({
  width: '305px',
  backgroundColor: 'rgba(255, 255, 255, 0.10)',
  boxShadow: '0px 4px 50px 0px rgba(0, 0, 0, 0.25)',
  '&:hover': {
    boxShadow: '0px 4px 50px 0px rgba(0, 0, 0, 0.25)',
    cursor: 'auto',
    backgroundColor: 'rgba(255, 255, 255, 0.10)',
  },
  padding: '40px',
  borderRadius: '15px',
  textTransform: 'none',
  fontSize: '20px',
  color: 'rgba(255, 255, 255, 0.50)',
  letterSpacing: '-0.2px',
});

const DialogContainer = styled(Box)({
  width: '100%',
  height: '100%',
  // background:
  //   'linear-gradient(0deg, rgba(0, 0, 0, 0.76) 0%, rgba(0, 0, 0, 0.00) 100%)',
  zIndex: 2,
  position: 'fixed',
  top: 0,
  display: 'flex',
  justifyContent: 'center',
  transition: 'all 1s ease',
  // opacity: 0,
});

const PathContainer = styled(Box)({
  position: 'fixed',
  bottom: '0',
  margin: '0 auto',
  width: '1027px',
  borderRadius: '25px 25px 0px 0px',
  background: 'rgba(0, 0, 0, 0.10)',
  backdropFilter: 'blur(80px)',
  display: 'flex',
  flexDirection: 'column',
  gap: '20px',
  padding: '20px 30px 40px  20px',
  zIndex: 2,
  transition: 'all .3s ease-in-out',
});

const RecordVoiceBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '75px',
  height: '75px',
  backgroundColor: 'rgba(255, 255, 255, 0.10)',
  alignSelf: 'center',
  borderRadius: '50%',
  transition: 'all 0.3s ease',
});

const PathOptionsContainer = styled(Box)({
  display: 'flex',
  gap: '20px',
  justifyContent: 'space-between',
  alignItems: 'stretch',
});

interface Props {
  moduleId: string;
  isListeningLoopVisible: boolean;
  onBranchSelected: (branch: string) => void;
  onConversationFinished: () => void;
}

const ExperienceViewerDialog = forwardRef(function ExperienceViewerDialog(
  {
    moduleId,
    isListeningLoopVisible,
    onBranchSelected,
    onConversationFinished,
  }: Props,
  ref,
) {
  useImperativeHandle(ref, () => ({
    startListening() {
      console.log('start listening');
      SpeechRecognition.startListening({
        language: 'en-US',
        interimResults: true,
      });
    },
    stopListening() {
      console.log('stop listening');
      SpeechRecognition.stopListening();
    },
    setIsPathContainerVisible(value: boolean) {
      setIsPathContainerVisible(value);
    },
  }));

  const [momentNumber, setMomentNumber] = useState(0);
  const [isPathContainerVisible, setIsPathContainerVisible] = useState(false);
  const [isSpeechRecognitionFailed, setSpeechRecognitionFailed] =
    useState(false);
  const [isSpeechRecognitionSuccessful, setSpeechRecognitionSuccessful] =
    useState(false);
  const [dialogResult, setDialogResult] = useState<string | null>(null);
  const [pathA, setPathA] = useState<Moment[]>([]);
  const [pathB, setPathB] = useState<Moment[]>([]);
  const [pathC, setPathC] = useState<Moment[]>([]);
  const [pathAOption, setPathAOption] = useState<string | null>(null);
  const [pathBOption, setPathBOption] = useState<string | null>(null);
  const [pathCOption, setPathCOption] = useState<string | null>(null);

  const { listening, browserSupportsSpeechRecognition, finalTranscript } =
    useSpeechRecognition();

  if (!browserSupportsSpeechRecognition) {
    alert(`Browser doesn't support speech recognition`);
  }

  const fetchModuleById = async () => {
    await getModuleById(moduleId ? moduleId : 'not-found')
      .then((module: FullModule) => {
        if (module.moduleScript) {
          setPathA(module.moduleScript.pathA);
          setPathB(module.moduleScript.pathB);
          setPathC(module.moduleScript.pathC);
        }
      })
      .catch((err: any) => {
        console.error('Failed to fetch module by id:', err);
        alert(`Failed to fetch module by id: ${err.message}`);
      });
  };
  useEffect(() => {
    fetchModuleById();
  }, []);

  useEffect(() => {
    stopListening();
    if (momentNumber !== 0 && momentNumber === pathA.length) {
      onConversationFinished();
    }
  }, [momentNumber]);

  useEffect(() => {
    if (momentNumber < 10 || !isPathContainerVisible) {
      setTimeout(() => {
        setPathAOption(pathA && pathA[momentNumber]?.learnerText);
        setPathBOption(pathB && pathB[momentNumber]?.learnerText);
        setPathCOption(pathC && pathC[momentNumber]?.learnerText);
      }, 500);
    }
  }, [pathA, pathB, pathC, isPathContainerVisible]);

  useEffect(() => {
    if (isListeningLoopVisible && !listening) {
      startListening();
    }
  }, [isListeningLoopVisible, listening]);

  const startListening = () =>
    SpeechRecognition.startListening({
      language: 'en-US',
      interimResults: true,
    });
  const stopListening = () => SpeechRecognition.stopListening();

  useEffect(() => {
    if (finalTranscript) {
      calculateLevenshteinDistance(finalTranscript);
    }
  }, [finalTranscript]);

  useEffect(() => {
    if (isListeningLoopVisible && !listening) {
      startListening();
    }
  }, [isListeningLoopVisible, listening]);

  useEffect(() => {
    if (isSpeechRecognitionSuccessful) {
      setSpeechRecognitionSuccessful(false);
      setTimeout(() => {
        setIsPathContainerVisible(false);
      }, 500);
      setTimeout(() => {
        setDialogResult(null);
      }, 700);
    }
  }, [isSpeechRecognitionSuccessful]);

  useEffect(() => {
    if (isSpeechRecognitionFailed) {
      setSpeechRecognitionFailed(false);
      setTimeout(() => {
        setDialogResult(null);
      }, 500);
    }
  }, [isSpeechRecognitionFailed]);

  const onSelectBranch = (branch: string) => {
    onBranchSelected(branch);
    setSpeechRecognitionSuccessful(true);
    setDialogResult('successful');
    if (momentNumber >= 10) {
      return;
    }
    setMomentNumber(momentNumber + 1);
  };

  const calculateLevenshteinDistance = (text: string) => {
    const options = [pathA, pathB, pathC].map((path) => {
      return path[momentNumber]?.learnerText;
    });

    const distances = options.map((option) => {
      return levenshtein(option ? option : '', text);
    });

    const minDistance = Math.min(...distances);
    if (minDistance > 35) {
      console.log('Skip proceed. Min distance ' + minDistance);
      setDialogResult('failed');
      setSpeechRecognitionFailed(true);
      startListening();
      return;
    }
    const minIndex = distances.indexOf(minDistance);
    if (minIndex === 0) {
      onSelectBranch(FIRST_OPTION);
    } else if (minIndex === 1) {
      onSelectBranch(SECOND_OPTION);
    } else if (minIndex === 2) {
      onSelectBranch(THIRD_OPTION);
    }
  };

  const getRecordVoiceBoxBackgroundColor = (): string => {
    if (dialogResult === 'failed') {
      return '#E21D1D';
    }

    if (dialogResult === 'successful') {
      return '#0065FF';
    }

    return 'rgba(255, 255, 255, 0.10)';
  };

  const getRecordVoiceBoxIcon = (): JSX.Element => {
    if (dialogResult === 'failed') {
      return <CloseIcon sx={{ color: 'white', fontSize: '50px' }} />;
    }

    if (dialogResult === 'successful') {
      return <CheckIcon sx={{ color: 'white', fontSize: '50px' }} />;
    }

    return (
      <Microphone
        color={'rgba(255, 255, 255, 0.50)'}
        // isUserSpeaking || (!isVideoVisible && listening)
        //   ? '#FFFFFF'
        //   : '#20BF6F'
        isAnimation={false}
      />
    );
  };

  return (
    <DialogContainer>
      <PathContainer sx={{ opacity: isPathContainerVisible ? 1 : 0 }}>
        <PathOptionsContainer>
          <DialogButton
            variant="contained"
            onClick={() => onSelectBranch(FIRST_OPTION)}
            // sx={{
            //   backgroundColor:
            //     selectedBranch === FIRST_CHOICE
            //       ? '#0065FF'
            //       : 'rgba(255, 255, 255, 0.05)',
            //   color:
            //     selectedBranch === FIRST_CHOICE
            //       ? '#FFFFFF'
            //       : 'rgba(255, 255, 255, 0.50)',
            // }}
          >
            {pathAOption}
          </DialogButton>
          <DialogButton
            variant="contained"
            onClick={() => onSelectBranch(SECOND_OPTION)}
          >
            {pathBOption}
          </DialogButton>
          <DialogButton
            variant="contained"
            onClick={() => onSelectBranch(THIRD_OPTION)}
          >
            {pathCOption}
          </DialogButton>
        </PathOptionsContainer>
        <RecordVoiceBox
          sx={{
            backgroundColor: getRecordVoiceBoxBackgroundColor(),
            // : listening && !isUserSpeaking && !isVideoVisible
            // ? '#0065FF'
            // : 'rgba(255, 255, 255, 0.10)',
          }}
        >
          {getRecordVoiceBoxIcon()}
        </RecordVoiceBox>
      </PathContainer>
    </DialogContainer>
  );
});

export default ExperienceViewerDialog;
