import React, { useState } from 'react';
import {
  Box,
  Typography,
  Dialog,
  TextField,
  CircularProgress,
} from '@mui/material';
import { CustomButton } from '../StyledComponents/SampleStyledComponents';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateModuleName } from '../../api/apiClient';
import { Module } from '../../types';

interface Props {
  onClose: () => void;
  open: boolean;
  moduleName?: string;
  moduleId: string;
}

function EditModuleNameDialog(props: Props) {
  const { onClose, open, moduleName, moduleId } = props;
  const [currentModuleName, setCurrentModuleName] = useState<
    string | undefined
  >(moduleName);
  const queryClient = useQueryClient();
  const {
    mutate: updateModuleNameMutation,
    isPending: isUpdateModuleNameLoading,
  } = useMutation({
    mutationFn: (moduleName: Module) => updateModuleName(moduleName),
  });
  const handleClose = () => {
    onClose();
  };
  const handleSave = () => {
    if (currentModuleName) {
      updateModuleNameMutation(
        {
          moduleId: moduleId,
          moduleName: currentModuleName,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['moduleListData'] });
            onClose();
          },
          onError: (error) => alert(error),
        },
      );
    }
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth="xl"
      sx={{
        '& .MuiDialog-paper': { borderRadius: '20px' },
        '& .MuiModal-backdrop': {
          backgroundColor: 'rgba(129, 136, 147, 0.50)',
        },
      }}
    >
      <Box
        sx={{
          width: 600,
          display: 'flex',
          flexDirection: 'column',
          gap: '30px',
          padding: '45px 30px',
        }}
      >
        <Typography sx={{ fontSize: '25px', letterSpacing: '-0.75px' }}>
          Edit module name
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <TextField
            id="create-module-textfield"
            fullWidth
            label="Module name"
            value={currentModuleName}
            onChange={(e) => setCurrentModuleName(e.target.value)}
            sx={{
              borderRadius: '15px',
              background: 'rgba(0, 0, 0, 0.05)',
              fieldset: {
                border: 'none',
              },
            }}
            inputProps={{ maxLength: 40 }}
          />
          <Box
            sx={{
              color: '#818893',
              fontSize: '15px',
              minWidth: '44px',
              textAlign: 'right',
            }}
          >
            {currentModuleName?.length}/40
          </Box>
        </Box>
        <CustomButton
          disabled={!currentModuleName}
          variant="contained"
          sx={{ width: '180px' }}
          onClick={handleSave}
        >
          Save
          {isUpdateModuleNameLoading && (
            <CircularProgress
              size={16}
              sx={{ color: 'white', marginLeft: '10px' }}
            />
          )}
        </CustomButton>
      </Box>
    </Dialog>
  );
}

export default EditModuleNameDialog;
