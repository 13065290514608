import React from 'react';
import type { FC } from 'react';
import { memo } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import DragHandleIcon from '@mui/icons-material/DragHandle';

const DragPreviewBox = styled(Box)({
  width: 'max-content',
  minWidth: '1050px',
  color: 'white',
  borderRadius: '10px',
  backgroundColor: '#0065FF',
  boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.25)',
  fontSize: '12px',
  padding: '10px',
  fontFamily: 'CircularStd-Medium',
  display: 'flex',
  alignItems: 'center',
});

export interface BoxDragPreviewProps {
  id: number;
  index: number;
}
export const BoxDragPreview: FC<BoxDragPreviewProps> = memo(
  function BoxDragPreview({ id, index }: { id: number; index: number }) {
    return (
      <DragPreviewBox>
        <DragHandleIcon sx={{ color: '#FFF', marginRight: '15px' }} />
        DRAG MOMENT {id + 1} TO NEW ({index + 1}) POSITION IN SCRIPT
      </DragPreviewBox>
    );
  },
);
