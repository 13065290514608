import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function ChevronRight() {
  return (
    <SvgIcon style={{ alignSelf: 'baseline' }}>
      <svg
        width="27"
        height="29"
        viewBox="0 0 27 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.125 21.9375L18.875 14.9375L12.125 7.9375"
          stroke="white"
          strokeWidth="2.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
