import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, TextField, Typography } from '@mui/material';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FullModule, ModuleScriptType } from '../../types';
import { updateModuleScript } from '../../api/apiClient';
import { useParams } from 'react-router-dom';

const StyledCard = styled(Box)({
  borderRadius: '15px',
  border: '1px solid #E2E2E2',
  background: '#FFF',
  width: 'min(60%, 500px)',
  fontFamily: 'Courier',
  fontSize: '15px',
  display: 'flex',
  gap: '15px',
  flexDirection: 'column',
  alignItems: 'flex-start',
  alignSelf: 'center',
  padding: '30px',
});

const DialogHeader = styled(Typography)({
  textTransform: 'uppercase',
  fontWeight: '700',
  fontFamily: 'Courier',
});

const DialogTextField = styled(TextField)({
  border: 'none',
  textarea: {
    border: 'none',
    fontFamily: 'Courier',
    lineHeight: '20px',
    letterSpacing: '-0.15px',
  },
  fieldset: {
    border: 'none',
  },
  '& .MuiInputBase-root': {
    padding: 0,
  },
});

const Moment0Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
  width: '100%',
});

const Moment0Header = styled(Typography)({
  textTransform: 'uppercase',
  fontSize: '12px',
  color: 'rgba(48, 52, 58, 0.50)',
});

function Moment0({
  content,
  characterName,
}: {
  content: string;
  characterName: string | undefined;
}) {
  const [editableText, setEditableText] = useState('');
  const queryClient = useQueryClient();
  const { moduleId } = useParams();

  useEffect(() => {
    setEditableText(content);
  }, [content]);

  const {
    mutate: updateMoment0TextInModuleScriptMutation,
    isPending: IsUpdateMoment0TextInModuleScriptMutationLoading,
  } = useMutation({
    mutationFn: (moduleScript: ModuleScriptType) =>
      updateModuleScript(moduleScript),
  });
  const updateMoment0TextInModuleScript = (moment0Text: string) => {
    const moduleData: FullModule | undefined = queryClient.getQueryData([
      'moduleDataById',
    ]);
    const newModuleScript = JSON.parse(
      JSON.stringify(moduleData?.moduleScript),
    );
    newModuleScript.moment0 = moment0Text;
    updateMoment0TextInModuleScriptMutation(
      {
        moduleName: moduleData?.moduleName,
        moduleId: moduleData?.moduleId ? moduleData?.moduleId : '',
        moduleScript: newModuleScript,
      },
      {
        onSuccess: ({ data }) => {
          queryClient.invalidateQueries({
            queryKey: ['moduleDataById', moduleId],
          });
          queryClient.setQueryData(['moduleDataById'], data);
          console.log('onSuccess updateMoment0TextInModuleScriptMutation');
        },
        onError: (error) => alert(error),
      },
    );
  };

  const onBlurTextArea = (currentText: string) => {
    if (content !== currentText) {
      updateMoment0TextInModuleScript(currentText);
    }
  };

  return (
    <Moment0Container>
      <Moment0Header>Moment 0</Moment0Header>
      <StyledCard>
        <DialogHeader>
          {characterName ? characterName : 'Character name'}
        </DialogHeader>
        <DialogTextField
          id="outlined-multiline-static"
          fullWidth
          multiline
          rows={4}
          value={editableText}
          onChange={(e) => setEditableText(e.target.value)}
          placeholder={
            "I'm having trouble with my customer. They keep changing their mind and it's making it difficult to get my project done on time. "
          }
          onBlur={(e) => onBlurTextArea(e.target.value)}
        />
      </StyledCard>
    </Moment0Container>
  );
}

export default Moment0;
