import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, Button } from '@mui/material';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '35px',
  color: '#FFF',
});

const QuestionText = styled(Typography)({
  fontSize: 'clamp(1.5625rem, 1.4525rem + 0.4695vw, 1.875rem)',
  lineHeight: '40px',
  width: 'min(100%, 500px)',
  '@media (max-width:375px)': {
    lineHeight: '30px',
  },
});

const RecommendationAnswerButtonsBlock = styled(Box)({
  display: 'flex',
  gap: '10px',
  '@media (max-width:375px)': {
    flexDirection: 'column',
  },
});

const RecommendationAnswerButton = styled(Button)({
  borderRadius: '15px',
  background: 'rgba(255, 255, 255, 0.10)',
  fontFamily: 'CircularStd-Medium',
  fontSize: '20px',
  color: '#D5D7D8',
  textTransform: 'none',
  padding: '15px 40px',
  '&:hover': {
    backgroundColor: '#2875EA',
    color: '#FFFFFF',
  },
});

const RecommendationCard = ({ handleNext }: { handleNext: () => void }) => {
  const handleRecommendation = (value: string) => {
    console.log('Recommendation:', value);
    handleNext();
  };

  return (
    <Container>
      <QuestionText>
        Would you recommend this virtual learning experience to others who need
        to learn the same skills?
      </QuestionText>
      <RecommendationAnswerButtonsBlock>
        <RecommendationAnswerButton onClick={() => handleRecommendation('Yes')}>
          Yes
        </RecommendationAnswerButton>
        <RecommendationAnswerButton onClick={() => handleRecommendation('No')}>
          No
        </RecommendationAnswerButton>
      </RecommendationAnswerButtonsBlock>
    </Container>
  );
};

export default RecommendationCard;
