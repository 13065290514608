import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { CustomButton } from '../StyledComponents/SampleStyledComponents';
import ResponseChoice from './ResponseChoice';
import FeedbackItem from './FeedbackItem';
import { ModuleEditorContext } from '../../reducers/ModuleEditorReducer';
import { FullModule, ModuleScriptType, Moment } from '../../types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateModuleScript } from '../../api/apiClient';
import { useParams } from 'react-router-dom';

interface Props {
  momentNumber: number;
  activeStep: number;
  handleNextMoment: () => void;
  module: FullModule;
}

const MomentFeedback = ({
  activeStep,
  momentNumber,
  handleNextMoment,
  module,
}: Props) => {
  const context = useContext(ModuleEditorContext);
  if (!context) {
    throw new Error(
      'MomentFeedback must be used within a ModuleEditorProvider',
    );
  }
  const { state } = context;
  const { keyMoments, moduleScript } = state;

  const [isEditing, setEditing] = useState(false);
  const getFeedbacks = (path: Moment[]) => {
    const arr: string[] = [];
    Array.from(new Set(keyMoments)).map((key: number) => {
      arr.push(path[key].feedback);
    });
    return arr;
  };

  const [pathAFeedback, setPathAFeedback] = useState<string[]>([]);
  const [pathBFeedback, setPathBFeedback] = useState<string[]>([]);
  const [pathCFeedback, setPathCFeedback] = useState<string[]>([]);

  useEffect(() => {
    setPathAFeedback(getFeedbacks(moduleScript.pathA));
    setPathBFeedback(getFeedbacks(moduleScript.pathB));
    setPathCFeedback(getFeedbacks(moduleScript.pathC));
  }, [keyMoments]);

  const { moduleId } = useParams();
  const queryClient = useQueryClient();
  const {
    mutate: updateFeedbackTextInModuleScriptMutation,
    isPending: IsUpdateFeedbackTextInModuleScriptMutationLoading,
  } = useMutation({
    mutationFn: (moduleScript: ModuleScriptType) =>
      updateModuleScript(moduleScript),
  });
  const updateFeedbackTextInModuleScript = (
    path: string,
    momentNumber: number,
    newMoment: any,
  ) => {
    const moduleData: FullModule | undefined = queryClient.getQueryData([
      'moduleDataById',
    ]);
    const newModuleScript = JSON.parse(
      JSON.stringify(moduleData?.moduleScript),
    );
    newModuleScript[path][momentNumber] = newMoment;
    console.log(newMoment, 'newMoment on change feedback text');

    updateFeedbackTextInModuleScriptMutation(
      {
        moduleName: moduleData?.moduleName,
        moduleId: moduleData?.moduleId ? moduleData?.moduleId : '',
        moduleScript: newModuleScript,
      },
      {
        onSuccess: ({ data }) => {
          queryClient.invalidateQueries({
            queryKey: ['moduleDataById', moduleId],
          });
          queryClient.setQueryData(['moduleDataById'], data);
          console.log('onSuccess updateFeedbackTextInModuleScriptMutation');
        },
        onError: (error) => alert(error),
      },
    );
  };

  const OnBlurTextarea = (
    path: string,
    currentText: string,
    prevText: string,
  ) => {
    let newMoment;
    if (path === 'pathA') {
      newMoment = {
        ...moduleScript.pathA[momentNumber],
        feedback: pathAFeedback[activeStep],
      };
    } else if (path === 'pathB') {
      newMoment = {
        ...moduleScript.pathB[momentNumber],
        feedback: pathBFeedback[activeStep],
      };
    } else if (path === 'pathC') {
      newMoment = {
        ...moduleScript.pathC[momentNumber],
        feedback: pathCFeedback[activeStep],
      };
    }

    if (currentText !== prevText) {
      updateFeedbackTextInModuleScript(path, momentNumber, newMoment);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '30px',
      }}
    >
      <Typography sx={{ width: 'min(70%, 510px)', textAlign: 'center' }}>
        These are the learner’s response choices in moment {momentNumber + 1}.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'stretch',
          gap: '15px',
          width: '100%',
        }}
      >
        <ResponseChoice
          name={module.communicationStyleA ? module.communicationStyleA : ''}
          mark={'+1'}
          backgroundColor={'#DCF1DD'}
          description={moduleScript.pathA[momentNumber].learnerText}
        />
        <ResponseChoice
          name={module.communicationStyleB ? module.communicationStyleB : ''}
          mark={'-1'}
          backgroundColor={'#FEF1D1'}
          description={moduleScript.pathB[momentNumber].learnerText}
        />
        <ResponseChoice
          name={module.communicationStyleC ? module.communicationStyleC : ''}
          mark={'-1'}
          backgroundColor={'#F9D6D6'}
          description={moduleScript.pathC[momentNumber].learnerText}
        />
      </Box>
      <Typography sx={{ width: 'min(70%, 510px)', textAlign: 'center' }}>
        This is the feedback the learner will receive at the end of the
        experience based on their choice. Press pencil to edit as needed.
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          gap: '15px',
          width: '100%',
        }}
      >
        <FeedbackItem
          icon={
            <CheckCircleIcon sx={{ color: '#20BF6F' }} fontSize={'medium'} />
          }
          name={'This is a one line description'}
          description={pathAFeedback[activeStep]}
          value={pathAFeedback[activeStep]}
          setEditing={(val) => setEditing(val)}
          handleChange={(e) => {
            const newValue = e.target.value;
            setPathAFeedback((prevFeedback) =>
              prevFeedback.map((feedback, index) =>
                index === activeStep ? newValue : feedback,
              ),
            );
          }}
          handleBlur={(text: string) => {
            OnBlurTextarea(
              'pathA',
              text,
              moduleScript.pathA[momentNumber].feedback,
            );
          }}
        />
        <FeedbackItem
          icon={<CancelIcon sx={{ color: '#CB4262' }} fontSize={'medium'} />}
          name={'This is a one line description'}
          description={pathBFeedback[activeStep]}
          value={pathBFeedback[activeStep]}
          setEditing={(val) => setEditing(val)}
          handleChange={(e) => {
            const newValue = e.target.value;
            setPathBFeedback((prevFeedback) =>
              prevFeedback.map((feedback, index) =>
                index === activeStep ? newValue : feedback,
              ),
            );
          }}
          handleBlur={(text: string) => {
            OnBlurTextarea(
              'pathB',
              text,
              moduleScript.pathB[momentNumber].feedback,
            );
          }}
        />
        <FeedbackItem
          icon={<CancelIcon sx={{ color: '#CB4262' }} fontSize={'medium'} />}
          name={'This is a one line description'}
          description={pathCFeedback[activeStep]}
          value={pathCFeedback[activeStep]}
          setEditing={(val) => setEditing(val)}
          handleChange={(e) => {
            const newValue = e.target.value;
            setPathCFeedback((prevFeedback) =>
              prevFeedback.map((feedback, index) =>
                index === activeStep ? newValue : feedback,
              ),
            );
          }}
          handleBlur={(text: string) => {
            OnBlurTextarea(
              'pathC',
              text,
              moduleScript.pathC[momentNumber].feedback,
            );
          }}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
        <CustomButton
          disabled={isEditing}
          variant="contained"
          onClick={handleNextMoment}
          endIcon={<ArrowDownwardIcon />}
        >
          Done with this moment
        </CustomButton>
      </Box>
    </Box>
  );
};

export default MomentFeedback;
