import { createContext } from 'react';
import { produce, Draft } from 'immer';
import { ModuleScript, Moment, Message, ModuleDisplay } from '../types';

export type ModuleEditorReducer = {
  moduleName: string;
  moduleId: string;
  communicationStyleA: string;
  communicationStyleB: string;
  communicationStyleC: string;
  moduleScript: ModuleScript;
  scriptLoading: boolean;
  summary: string;
  videosLoading: boolean;
  messages: Message[];
  character?: string;
  environment?: string;
  moduleList?: ModuleDisplay[];
  inProgressIds?: string[];
  surveyQuestions: string[];
  keyMoments: Set<number>;
  thumbnailUrl?: string;
  learningObjective?: string;
  topic?: string;
  location?: string;
  learnerRole?: string;
  conflictArea?: string;
  scenario?: string;
  incitingIncident?: string;
  characterPersona?: string;
  characterName?: string;
  voiceId?: string;
};

const initialModuleScript = {
  pathA: [
    { learnerText: '', characterText: '', feedback: '' },
    { learnerText: '', characterText: '', feedback: '' },
    { learnerText: '', characterText: '', feedback: '' },
    { learnerText: '', characterText: '', feedback: '' },
    { learnerText: '', characterText: '', feedback: '' },
    { learnerText: '', characterText: '', feedback: '' },
    { learnerText: '', characterText: '', feedback: '' },
    { learnerText: '', characterText: '', feedback: '' },
    { learnerText: '', characterText: '', feedback: '' },
    { learnerText: '', characterText: '', feedback: '' },
    // You can add more Moments as needed for each path.
  ],
  pathB: [
    { learnerText: '', characterText: '', feedback: '' },
    { learnerText: '', characterText: '', feedback: '' },
    { learnerText: '', characterText: '', feedback: '' },
    { learnerText: '', characterText: '', feedback: '' },
    { learnerText: '', characterText: '', feedback: '' },
    { learnerText: '', characterText: '', feedback: '' },
    { learnerText: '', characterText: '', feedback: '' },
    { learnerText: '', characterText: '', feedback: '' },
    { learnerText: '', characterText: '', feedback: '' },
    { learnerText: '', characterText: '', feedback: '' },
    // ... and so on.
  ],
  pathC: [
    { learnerText: '', characterText: '', feedback: '' },
    { learnerText: '', characterText: '', feedback: '' },
    { learnerText: '', characterText: '', feedback: '' },
    { learnerText: '', characterText: '', feedback: '' },
    { learnerText: '', characterText: '', feedback: '' },
    { learnerText: '', characterText: '', feedback: '' },
    { learnerText: '', characterText: '', feedback: '' },
    { learnerText: '', characterText: '', feedback: '' },
    { learnerText: '', characterText: '', feedback: '' },
    { learnerText: '', characterText: '', feedback: '' },
    // ... and so on.
  ],
  summary: '',
  moment0: '',
  // You can add more paths as needed.
} as ModuleScript;

export type ReducerAction =
  | { type: 'GENERATE_SCRIPT_START' }
  | { type: 'GENERATE_SCRIPT_SUCCESS'; payload: ModuleScript }
  | { type: 'GENERATE_SCRIPT_FAILURE'; payload: any }
  | { type: 'ADD_MESSAGE'; payload: Message }
  | { type: 'REFRESH_MESSAGES'; payload: Message[] }
  | { type: 'REFRESH_MODULE_SCRIPT'; payload: ModuleScript }
  | { type: 'SET_IN_PROGRESS_IDS'; payload: string[] }
  | { type: 'SET_FIELDS'; payload: Partial<ModuleEditorReducer> };

type ModuleEditorContextType = {
  state: ModuleEditorReducer;
  dispatch: React.Dispatch<any>;
};

export const ModuleEditorContext = createContext<
  ModuleEditorContextType | undefined
>(undefined);

export const initialState: ModuleEditorReducer = {
  moduleName: '',
  moduleId: '',
  communicationStyleA: 'Empathetic',
  communicationStyleB: 'Rude',
  communicationStyleC: 'Hostile',
  moduleScript: initialModuleScript,
  scriptLoading: false,
  summary: '',
  videosLoading: false,
  // messages: [initialMessage, setupMessage],
  messages: [
    {
      content:
        'Step 1. What is the topic area? (Suggestions: Empathy, Diversity, Customer Experience, Sales)',
      role: 'system',
    },
  ],
  surveyQuestions: ['', ''],
  keyMoments: new Set(),
  character: undefined,
  characterName: undefined,
  environment: undefined,
  learningObjective: undefined,
};

export const setFields = (fields: Partial<ModuleEditorReducer>) => ({
  type: 'SET_FIELDS',
  payload: fields,
});

export default function moduleEditorReducer(
  state: ModuleEditorReducer,
  action: ReducerAction,
): ModuleEditorReducer {
  return produce(state, (draft: Draft<ModuleEditorReducer>) => {
    switch (action.type) {
      case 'ADD_MESSAGE':
        draft.messages.push(action.payload);
        break;
      case 'REFRESH_MESSAGES':
        draft.messages = initialState.messages;
        break;
      case 'REFRESH_MODULE_SCRIPT':
        draft.moduleScript = initialState.moduleScript;
        break;
      case 'SET_IN_PROGRESS_IDS':
        draft.inProgressIds = action.payload;
        break;
      case 'SET_FIELDS':
        Object.assign(draft, action.payload);
        break;
      default:
        throw Error('Unknown action');
    }
  });
}
