import React, { useCallback, useState, useEffect } from 'react';
import update from 'immutability-helper';

import MomentCard from './MomentCard/MomentCard';

import { FullModule, Moment } from '../../types';
import { useQueryClient } from '@tanstack/react-query';
import { CustomDragLayer } from './MomentCard/CustomDragLayer';

interface Props {
  module: FullModule;
}

export interface Item {
  id: number;
  pathA: Moment;
  pathB: Moment;
  pathC: Moment;
}

function ScriptMoments({ module }: Props) {
  const [cards, setCards] = useState<Item[]>([]);
  const queryClient = useQueryClient();

  const numberOfMoments = module.moduleScript?.pathA.length;
  const loadCardsToState = (module: FullModule) => {
    const cardsArr: any = [];
    [...Array(numberOfMoments)].map((moment: number, i: number) => {
      cardsArr.push({
        id: i,
        pathA: module.moduleScript?.pathA[i],
        pathB: module.moduleScript?.pathB[i],
        pathC: module.moduleScript?.pathC[i],
      });
    });
    setCards(cardsArr);
  };

  useEffect(() => {
    const moduleData: FullModule | undefined = queryClient.getQueryData([
      'moduleDataById',
    ]);
    if (moduleData) {
      loadCardsToState(moduleData);
    }
  }, []);

  useEffect(() => {
    loadCardsToState(module);
  }, [module]);

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setCards((prevCards: Item[]) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex] as Item],
        ],
      }),
    );
  }, []);

  const renderCard = useCallback(
    (
      module: FullModule,
      card: { id: number; pathA: Moment; pathB: Moment; pathC: Moment },
      index: number,
    ) => {
      return (
        <MomentCard
          key={card.id}
          index={index}
          id={card.id}
          module={module}
          momentNumber={index}
          pathA={card.pathA}
          pathB={card.pathB}
          pathC={card.pathC}
          moveCard={moveCard}
        />
      );
    },
    [],
  );

  return (
    <>
      {cards.map((card, i) => {
        const moduleData: FullModule | undefined = queryClient.getQueryData([
          'moduleDataById',
        ]);
        return renderCard(moduleData ? moduleData : module, card, i);
      })}
      <CustomDragLayer />
    </>
  );
}

export default ScriptMoments;
