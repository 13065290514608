import React, { useEffect, useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { getModuleVoiceOver } from '../../api/apiClient';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '35px',
  color: '#FFF',
});

const MainHeader = styled(Typography)({
  fontWeight: 700,
  fontSize: 'clamp(1.875rem, 1.3248rem + 2.3474vw, 3.4375rem)',
  letterSpacing: '-1.65px',
});

const RegularText = styled(Typography)({
  fontWeight: 450,
  fontSize: '20px',
  lineHeight: '26px',
  letterSpacing: '-0.2px',
  fontFamily: 'CircularStd-Medium',
  maxWidth: '50ch',
  width: 'min(100%, 500px)',
});

const Skill = styled(Box)({
  borderRadius: '15px',
  border: '3px solid rgba(255, 255, 255, 0.10)',
  display: 'flex',
  alignItems: 'center',
  padding: '25px 40px',
  '@media (max-width:375px)': {
    padding: '25px',
    alignItems: 'flex-start',
  },
});

const CheckIcon = styled(CheckCircleIcon)({
  color: '#20BF6F',
  marginRight: '20px',
});

function LearningObjective({
  learningObjective,
  summary,
  moduleId,
}: {
  learningObjective: string;
  summary: string;
  moduleId: string;
}) {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [voiceOverUrl, setVoiceOverUrl] = useState<string | null>(null);

  useEffect(() => {
    const loadVoiceOver = async () => {
      try {
        // Fetch the voiceover data
        const response = await getModuleVoiceOver({
          moduleId: moduleId, // Adjust moduleId as needed
          voiceOverKey: 'learningObjectiveVO', // Adjust the voiceOverKey as needed
        });

        if (response.data.url) {
          // Set the voiceover URL
          setVoiceOverUrl(response.data.url);
        }
      } catch (error) {
        console.error('Error loading voiceover:', error);
      }
    };

    loadVoiceOver();
  }, [moduleId]);

  useEffect(() => {
    const audioElement = audioRef.current;

    if (audioElement && voiceOverUrl) {
      // Set the audio source using the voiceover URL
      audioElement.src = voiceOverUrl;

      // Autoplay the audio when the component mounts
      audioElement.play().catch((error) => {
        console.error('Audio playback error:', error);
      });
    }
  }, [voiceOverUrl]);

  return (
    <Container>
      <MainHeader>Skills for Success</MainHeader>
      <RegularText>{summary}</RegularText>
      <Skill>
        <CheckIcon />
        <RegularText>{learningObjective}</RegularText>
      </Skill>
      <audio ref={audioRef} controls style={{ display: 'none' }} />
    </Container>
  );
}

export default LearningObjective;
