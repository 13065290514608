import React, { useState, ReactNode } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { CustomButton } from './StyledComponents/SampleStyledComponents';
import {
  CharacterInfoScreen,
  LearningObjectiveScreen,
  Layout,
  ModuleDescription,
  ModuleThumbnail,
  SurveyQuestionScreen,
  FinishModuleScreen,
  FinishedStep,
  WelcomeScreenNarration,
} from './Details/index';
import { FullModule } from '../types';

const DetailsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
  marginBottom: '30px',
});

interface Props {
  module: FullModule;
}

function Details({ module }: Props) {
  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    {
      header: 'Module description',
      description: 'Below please write a description of your module.',
      step: (
        <ModuleDescription
          moduleName={module.moduleName ? module.moduleName : ''}
          moduleSummary={module.summary ? module.summary : ''}
        />
      ),
    },
    {
      header: 'Module thumbnail',
      description:
        'Below is how your module’s thumbnail will look.\n' +
        'Upload your own to customize, or press “next” to proceed.',
      step: <ModuleThumbnail />,
    },
    {
      header: 'Welcome screen narration',
      description:
        'The accompanying voiceover narration will be played over ' +
        'a screen with the module name and the thumbnail. It should ' +
        'be a short introduction to the experience.',
      step: (
        <WelcomeScreenNarration
          titleVoiceOver={module.voiceOvers ? module.voiceOvers?.titleVO : ''}
        />
      ),
    },
    {
      header: 'Character info screen narration',
      description:
        'The accompanying voiceover narration will be played over ' +
        'the character intro screen. Modify as needed and then press ‘next’.',
      step: (
        <CharacterInfoScreen
          characterVoiceOver={
            module.voiceOvers ? module.voiceOvers?.characterVO : ''
          }
        />
      ),
    },
    {
      header: 'Learning objective screen',
      description:
        'This is the screen the learner will see before starting ' +
        'the experience. Take a few sentences to set up the conversation scene ' +
        'with necessary information about the "inciting incident". \n \n Then, fill in the ' +
        'learning objective (it should be phrased as a verb).',
      step: (
        <LearningObjectiveScreen
          learningObjective={
            module.learningObjective ? module.learningObjective : ''
          }
        />
      ),
    },
    {
      header: 'Pre-experience survey question #1',
      description:
        'This is the first survey question the learner will see. Complete the sentence "How confident are you in..."',
      additional:
        'For example: "How confident are you in communicating change to your team?"',
      step: (
        <SurveyQuestionScreen
          surveyQuestions={
            module.surveyQuestions ? module.surveyQuestions : ['']
          }
        />
      ),
    },
    {
      header: 'That’s it!',
      description:
        'You have finished customizing your experience. Once you press “Build module,” ' +
        'we will create your module.',
      step: <FinishModuleScreen />,
    },
  ];

  interface LayoutProps {
    step: ReactNode;
    header: string;
    description: string;
    additional?: string;
  }

  const wrapLayout = ({
    step,
    header,
    description,
    additional,
  }: LayoutProps) => (
    <Layout header={header} description={description} additional={additional}>
      {step}
    </Layout>
  );

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleChangeStep = (step: number) => {
    setActiveStep(step);
  };

  return (
    <DetailsContainer>
      {activeStep > 0
        ? steps.map((step, index) => {
            if (index < activeStep) {
              return (
                <FinishedStep
                  key={index}
                  stepName={step.header}
                  handleChangeStep={() => handleChangeStep(index)}
                />
              );
            }
          })
        : null}
      {wrapLayout(steps[activeStep])}
      {activeStep !== steps.length - 1 && (
        <CustomButton
          variant="contained"
          onClick={handleNext}
          endIcon={<ArrowDownwardIcon />}
        >
          Next
        </CustomButton>
      )}
    </DetailsContainer>
  );
}

export default Details;
