import React, { useState } from 'react';
import {
  Container,
  SettingsContainer,
  SettingBox,
  SettingLabelBox,
  SettingHeader,
  SettingCounter,
  CustomTextField,
} from '../StyledComponents/SampleStyledComponents';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FullModule, VoiceOvers } from '../../types';
import { updateVoiceOvers } from '../../api/apiClient';

function WelcomeScreenNarration({
  titleVoiceOver,
}: {
  titleVoiceOver: string;
}) {
  const [narratorDescription, setNarratorDescription] =
    useState(titleVoiceOver);

  const { moduleId } = useParams();
  const queryClient = useQueryClient();
  const {
    mutate: updateTitleVOMutation,
    isPending: updateTitleVOMutationLoading,
  } = useMutation({
    mutationFn: (voiceOvers: VoiceOvers) => updateVoiceOvers(voiceOvers),
  });

  const onBlurTitleVO = (text: string) => {
    setNarratorDescription(text);
    const moduleData: FullModule | undefined = queryClient.getQueryData([
      'moduleDataById',
    ]);
    const newVoiceOvers = JSON.parse(JSON.stringify(moduleData?.voiceOvers));
    newVoiceOvers.titleVO = text;
    if (moduleData?.voiceOvers?.titleVO !== text && moduleId) {
      updateTitleVOMutation(
        {
          moduleId: moduleId,
          voiceOvers: newVoiceOvers,
        },
        {
          onSuccess: ({ data }) => {
            queryClient.invalidateQueries({
              queryKey: ['moduleDataById', moduleId],
            });
            queryClient.setQueryData(['moduleDataById'], data);
            console.log('onSuccess updateTitleVOMutation');
          },
          onError: (error) => alert(error),
        },
      );
    }
  };

  return (
    <Container>
      <SettingsContainer>
        <SettingBox>
          <SettingLabelBox>
            <SettingHeader>Narrator</SettingHeader>
            <SettingCounter>{narratorDescription.length}/60</SettingCounter>
          </SettingLabelBox>
          <CustomTextField
            id="outlined-multiline-static"
            fullWidth
            inputProps={{ maxLength: 60 }}
            multiline
            rows={4}
            value={narratorDescription}
            onChange={(e) => setNarratorDescription(e.target.value)}
            onBlur={(e) => onBlurTitleVO(e.target.value)}
            placeholder={
              'Welcome to [Module Title]. You are about to practice your skills at [topic].'
            }
          />
        </SettingBox>
      </SettingsContainer>
    </Container>
  );
}

export default WelcomeScreenNarration;
