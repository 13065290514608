import React from 'react';
import { Box, Button, CircularProgress } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { styled } from '@mui/material/styles';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  FullModule,
  KeyMoments as KeyMomentsType,
  ModuleScriptType,
} from '../../types';
import { updateKeyMoments, updateModuleScript } from '../../api/apiClient';
import { useParams } from 'react-router-dom';

export const AddMomentBox = styled(Box)({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
});
export const AddMomentButton = styled(Button)({
  fontFamily: 'CircularStd-Medium',
  fontSize: '12px',
  minWidth: 'fit-content',
  borderRadius: '50%',
  padding: '5px',
  backgroundColor: '#E2E2E2',
  textTransform: 'none',
  boxShadow: 'none',
  transition: 'all 0.3s ease',
  position: 'unset',
  zIndex: 0,
  '&::before': {
    content: "''",
    height: '1px',
    width: '100%',
    position: 'absolute',
    backgroundColor: '#E2E2E2',
    left: 0,
    zIndex: -1,
    pointerEvents: 'none',
  },
  '& .addMomentButtonText': {
    display: 'none',
    marginLeft: '10px',
  },
  '&:hover': {
    borderRadius: '50px',
    padding: '10px 15px',
    backgroundColor: '#0065FF',
    lineHeight: '12px',
    '&::before': {
      backgroundColor: '#0065FF',
      height: '4px',
    },
    '& .addMomentButtonText': {
      display: 'block',
    },
  },
});
const AddNewMoment = ({ momentId }: { momentId: number }) => {
  const { moduleId } = useParams();
  const queryClient = useQueryClient();
  const {
    mutate: addNewMomentInModuleScriptMutation,
    isPending: IsAddNewMomentInModuleScriptMutationLoading,
  } = useMutation({
    mutationFn: (moduleScript: ModuleScriptType) =>
      updateModuleScript(moduleScript),
  });

  const { mutate: moveKeyMomentsMutation, isPending: isMoveKeyMomentsLoading } =
    useMutation({
      mutationFn: (keyMoments: KeyMomentsType) => updateKeyMoments(keyMoments),
    });

  const moveKeyMoments = (moduleData: FullModule) => {
    if (moduleData?.keyMoments) {
      const newKeyMoments = JSON.parse(JSON.stringify(moduleData?.keyMoments));
      newKeyMoments.map((keyMoment: number, i: number) => {
        if (keyMoment >= momentId) {
          newKeyMoments[i] = keyMoment + 1;
        }
      });
      moveKeyMomentsMutation(
        {
          moduleName: moduleData?.moduleName,
          moduleId: moduleData?.moduleId ? moduleData?.moduleId : '',
          keyMoments: newKeyMoments,
        },
        {
          onSuccess: ({ data }) => {
            queryClient.invalidateQueries({
              queryKey: ['moduleDataById', moduleId],
            });
            queryClient.setQueryData(['moduleDataById'], data);
            console.log('onSuccess moveKeyMomentsMutation');
          },
          onError: (error) => alert(error),
        },
      );
    }
  };

  const onAddNewMoment = () => {
    const emptyPath = { learnerText: '', characterText: '', feedback: '' };
    const moduleData: FullModule | undefined = queryClient.getQueryData([
      'moduleDataById',
    ]);
    const newModuleScript = JSON.parse(
      JSON.stringify(moduleData?.moduleScript),
    );
    newModuleScript.pathA?.splice(momentId, 0, emptyPath);
    newModuleScript.pathB?.splice(momentId, 0, emptyPath);
    newModuleScript.pathC?.splice(momentId, 0, emptyPath);
    addNewMomentInModuleScriptMutation(
      {
        moduleName: moduleData?.moduleName,
        moduleId: moduleData?.moduleId ? moduleData?.moduleId : '',
        moduleScript: newModuleScript,
      },
      {
        onSuccess: ({ data }) => {
          queryClient.invalidateQueries({
            queryKey: ['moduleDataById', moduleId],
          });
          queryClient.setQueryData(['moduleDataById'], data);
          console.log('onSuccess addNewMomentInModuleScriptMutation');
          moveKeyMoments(data);
        },
        onError: (error) => alert(error),
      },
    );
    console.log('add new moment');
  };
  return (
    <AddMomentBox>
      <AddMomentButton variant="contained" onClick={onAddNewMoment}>
        {IsAddNewMomentInModuleScriptMutationLoading ? (
          <CircularProgress size={16} sx={{ color: 'white' }} />
        ) : (
          <AddIcon sx={{ fontSize: '16px' }} />
        )}
        <div className={'addMomentButtonText'}>Add moment</div>
      </AddMomentButton>
    </AddMomentBox>
  );
};

export default AddNewMoment;
