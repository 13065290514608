import React, { useEffect, useState, useContext, useRef } from 'react';
import {
  Box,
  Button,
  LinearProgress,
  Typography,
  InputBase,
} from '@mui/material';
import axios from 'axios';
import ChatMessage from './SceneSetup/ChatMessage';
import FinishedSceneSetupModal from './SceneSetup/FinishedSceneSetupModal';

import {
  ModuleEditorContext,
  setFields,
} from '../reducers/ModuleEditorReducer';
import { styled } from '@mui/material/styles';
import SendIconSvg from './svg/SendIcon';
import { useMutation } from '@tanstack/react-query';
import { Chat } from '../types';
import { updateChat } from '../api/apiClient';
import { useParams } from 'react-router-dom';

const ChatbotContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderRadius: '25px',
  background: '#FFF',
  width: 'min(100%, 545px)',
  margin: '0 auto',
  padding: '0px',
});

function Chatbot({ setTab }: { setTab: (tab: number) => void }) {
  const { moduleId } = useParams();
  const messagesListRef = useRef<HTMLDivElement>(null);
  const [messageInput, setMessageInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [ctaVisible, setCtaVisible] = useState(false);
  const [chatData, setChatData] = useState({ moduleId: '' });

  const context = useContext(ModuleEditorContext);
  if (!context) {
    throw new Error(
      'ChatContainer must be used within a ModuleEditorContext.Provider',
    );
  }

  const { state, dispatch } = context;
  const { messages } = state;

  const { mutate: updateChatMutation, isPending: isUpdateChatLoading } =
    useMutation({
      mutationFn: (chat: Chat) => updateChat(chat),
    });
  const bottomRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  useEffect(() => {
    if (messagesListRef.current) {
      messagesListRef.current.scrollTop = messagesListRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    sendMessage(messageInput);
    setMessageInput('');
  };

  const sendMessage = async (content: string) => {
    dispatch({
      type: 'ADD_MESSAGE',
      payload: {
        content: content,
        role: 'user',
      },
    });
    setIsLoading(true);
    const requestData = {
      message: content,
      messagesHistory: messages,
    };
    const { data } = await axios.post('/chat', requestData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log('data', data.choices[0].message.content);
    // TODO - handle this better
    if (data.choices[0].message.content[0] === '{') {
      console.log('data again', data.choices[0].message.content);
      const json = JSON.parse(data.choices[0].message.content);
      const fields = {
        moduleId: moduleId ? moduleId : '',
        topic: json.topic,
        location: json.location,
        learnerRole: json.learnerRole,
        characterRole: json.characterRole,
        learningObjective: json.learningObjective,
        conflictArea: json.conflictArea,
        scenario: json.scenario,
        incitingIncident: json.incitingIncident,
        characterPersona: json.characterPersona,
      };
      setChatData(fields);
      dispatch(setFields(fields));
      setCtaVisible(true);
    } else {
      dispatch({
        type: 'ADD_MESSAGE',
        payload: {
          content: data.choices[0].message.content,
          role: 'system',
        },
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      <ChatbotContainer>
        <Box
          ref={messagesListRef}
          sx={{
            minHeight: 420,
            padding: '45px',
          }}
        >
          <Box>
            {messages.map((message, index) => (
              <ChatMessage
                key={index}
                content={message.content}
                isCustomer={message.role === 'user'}
              />
            ))}
            {isLoading ? (
              <Box>
                <Typography sx={{ fontStyle: 'italic' }}>
                  Waiting for response...
                </Typography>
                <LinearProgress />
              </Box>
            ) : null}
          </Box>
        </Box>
        <Box
          ref={bottomRef}
          component="form"
          sx={{
            display: 'flex',
            width: '100%',
            padding: '20px 45px',
            gap: '10px',
            borderTop: '1px solid rgba(0, 0, 0, 0.05)',
            boxSizing: 'border-box',
          }}
          onSubmit={handleSubmit}
        >
          <InputBase
            onChange={(event) => setMessageInput(event.target.value)}
            fullWidth
            value={messageInput}
            placeholder="Type here"
          />
          <Button
            variant={'contained'}
            type="submit"
            sx={{
              borderRadius: '10px',
              padding: '5px 10px 5px 5px',
              minWidth: '40px',
            }}
          >
            <SendIconSvg />
          </Button>
        </Box>
      </ChatbotContainer>
      <FinishedSceneSetupModal
        open={ctaVisible}
        updateModule={() => {
          updateChatMutation(chatData, {
            onSuccess: () => {
              console.log('onSuccess updateChatMutation');
              setCtaVisible(false);
              setTab(1);
            },
          });
        }}
        isLoading={isUpdateChatLoading}
      />
    </>
  );
}

export default Chatbot;
