import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';

interface Props {
  children: ReactNode | ReactNode[];
}

const Background = styled(Container)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'column',
  padding: 'clamp(30px, 5%, 50px)',
  width: '100%',
  minHeight: '100vh',
  '&::before': {
    content: "''",
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'black',

    // todo uncomment if we need background image for the Player app
    // backgroundPosition: 'center',
    // backgroundSize: 'cover',
    // backgroundRepeat: 'no-repeat',
    // backgroundImage: `url(${mainBackground})`,
  },
});

const BackgroundWrapper = ({ children }: Props) => (
  <Background>{children}</Background>
);

export default BackgroundWrapper;
