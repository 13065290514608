import React, { useRef, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';
import { getModuleVoiceOver } from '../../api/apiClient';

import bg from '../../images/logo-full-colorful-image.png';

const Container = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  textAlign: 'center',
  gap: '30px',
});

const Logo = styled('img')({
  width: 'min(50%, 280px)',
  height: 'auto',
});

const ModuleName = styled(Typography)({
  fontFamily: 'CircularStd-Medium',
  fontSize: 'clamp(1.875rem, 1.6549rem + 0.939vw, 2.5rem)',
  letterSpacing: '-0.4px',
  color: '#FFF',
});

const LearningObjective = ({
  moduleName,
  moduleId,
}: {
  moduleName: string;
  moduleId: string;
}) => {
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const [voiceOverUrl, setVoiceOverUrl] = useState<string | null>(null);

  useEffect(() => {
    const loadVoiceOver = async () => {
      try {
        // Fetch the voiceover data
        const response = await getModuleVoiceOver({
          moduleId: moduleId, // Adjust moduleId as needed
          voiceOverKey: 'titleVO', // Adjust the voiceOverKey as needed
        });

        if (response.data.url) {
          // Set the voiceover URL
          setVoiceOverUrl(response.data.url);
        }
      } catch (error) {
        console.error('Error loading voiceover:', error);
      }
    };

    loadVoiceOver();
  }, [moduleId]);

  useEffect(() => {
    const audioElement = audioRef.current;

    if (audioElement && voiceOverUrl) {
      // Set the audio source using the voiceover URL
      audioElement.src = voiceOverUrl;

      // Autoplay the audio when the component mounts
      audioElement.play().catch((error) => {
        console.error('Audio playback error:', error);
      });
    }
  }, [voiceOverUrl]);

  return (
    <Container>
      <Logo src={bg} alt="" />
      <ModuleName>{moduleName}</ModuleName>
      <audio ref={audioRef} controls style={{ display: 'none' }} />
    </Container>
  );
};

export default LearningObjective;
