import React, { ReactNode, useState } from 'react';
import { Box } from '@mui/material';
import Layout from '../components/Details/Layout';
import CustomSlider from '../components/Character/CustomSlider';
import { updateCharacter, updateEnvironment } from '../api/apiClient';

import alisha from '../images/characters/alisha.png';
import emma from '../images/characters/emma.png';
import evelyn from '../images/characters/evelyn.png';
import jimmy from '../images/characters/jimmy.png';
import parker from '../images/characters/parker.png';
import sally from '../images/characters/sally.png';
import sana from '../images/characters/sana.png';
import will from '../images/characters/will.png';
import emily from '../images/characters/emily.png';
import jacob from '../images/characters/jacob.png';
import matthew from '../images/characters/matthew.png';
import samantha from '../images/characters/samantha.png';
import sarah from '../images/characters/sarah.png';

import home from '../images/backgrounds/home-1.png';
import office1 from '../images/backgrounds/office-1.png';
import office2 from '../images/backgrounds/office-2.png';
import office3 from '../images/backgrounds/office-3.png';
import office4 from '../images/backgrounds/office-4.png';
import office5 from '../images/backgrounds/office-5.png';
import office6 from '../images/backgrounds/office-6.png';

import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { CustomButton } from './StyledComponents/SampleStyledComponents';
import { styled } from '@mui/material/styles';
import SliderItem from './Character/SliderItem';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Character as CharacterType,
  Environment as EnvironmentType,
  FullModule,
} from '../types';
import { useParams } from 'react-router-dom';

const CharacterContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
});

const pics = {
  '05c90ba7-33e4-49ac-bc6e-3840973a31fc': {
    image: alisha,
    name: 'Alisha',
    id: '05c90ba7-33e4-49ac-bc6e-3840973a31fc',
    voiceId: '21m00Tcm4TlvDq8ikWAM',
  },
  'be374328-34cd-48ec-9f6c-9b5393024af3': {
    image: emma,
    name: 'Emma',
    id: 'be374328-34cd-48ec-9f6c-9b5393024af3',
    voiceId: '21m00Tcm4TlvDq8ikWAM',
  },
  '1303716e-6ba2-4bf1-a61f-1e5fd3a06cbd': {
    image: evelyn,
    name: 'Evelyn',
    id: '1303716e-6ba2-4bf1-a61f-1e5fd3a06cbd',
    voiceId: '21m00Tcm4TlvDq8ikWAM',
  },
  '8fe0e460-99a3-445b-a244-0518cf1cc2d2': {
    image: jimmy,
    name: 'Jimmy',
    id: '8fe0e460-99a3-445b-a244-0518cf1cc2d2',
    voiceId: 'tm3TDAeMaFEyTQ30JtY3',
  },
  '888255b8-7311-4047-99c3-b4d9fa306712': {
    image: parker,
    name: 'Parker',
    id: '888255b8-7311-4047-99c3-b4d9fa306712',
    voiceId: 'tm3TDAeMaFEyTQ30JtY3',
  },
  '5ebb954e-c5ea-40f3-be6e-a7a23923c13b': {
    image: sally,
    name: 'Sally',
    id: '5ebb954e-c5ea-40f3-be6e-a7a23923c13b',
    voiceId: '21m00Tcm4TlvDq8ikWAM',
  },
  '4095cd7c-40ab-4134-9c76-80f07c845b31': {
    image: sana,
    name: 'Sana',
    id: '4095cd7c-40ab-4134-9c76-80f07c845b31',
    voiceId: '21m00Tcm4TlvDq8ikWAM',
  },
  '4612d242-cbb6-4993-8b18-ec62e1706bcf': {
    image: will,
    name: 'Will',
    id: '4612d242-cbb6-4993-8b18-ec62e1706bcf',
    voiceId: 'tm3TDAeMaFEyTQ30JtY3',
  },
  'd446cd64-4968-4606-82b9-bf0128dfb2bf': {
    image: jacob,
    name: 'Jacob',
    id: 'd446cd64-4968-4606-82b9-bf0128dfb2bf',
    voiceId: 'tm3TDAeMaFEyTQ30JtY3',
  },
  '84be85f0-0f2e-4898-b640-8db83c441d51': {
    image: matthew,
    name: 'Matthew',
    id: '84be85f0-0f2e-4898-b640-8db83c441d51',
    voiceId: 'tm3TDAeMaFEyTQ30JtY3',
  },
  '494fabfc-beef-4af2-b9fd-23ae764bb8c0': {
    image: emily,
    name: 'Emily',
    id: '494fabfc-beef-4af2-b9fd-23ae764bb8c0',
    voiceId: '21m00Tcm4TlvDq8ikWAM',
  },
  '8b746bcb-1d14-414b-86b6-cdf12b928b63': {
    image: sarah,
    name: 'Sarah',
    id: '8b746bcb-1d14-414b-86b6-cdf12b928b63',
    voiceId: '21m00Tcm4TlvDq8ikWAM',
  },
  '0e382ac1-c068-4278-a7ba-dd5e624c9472': {
    image: samantha,
    name: 'Samantha',
    id: '0e382ac1-c068-4278-a7ba-dd5e624c9472',
    voiceId: '21m00Tcm4TlvDq8ikWAM',
  },
};

const envs = {
  'workspace-media.3651a5de-ebc1-4712-9b7b-e472de101a8b': {
    image: home,
    name: 'Environment 1',
    id: 'workspace-media.3651a5de-ebc1-4712-9b7b-e472de101a8b',
  },
  'workspace-media.fca651f0-505a-4c06-bc4a-bed177cc0855': {
    image: office1,
    name: 'Environment 2',
    id: 'workspace-media.fca651f0-505a-4c06-bc4a-bed177cc0855',
  },
  'workspace-media.e4b83515-c5b3-46fe-a48e-21722a341daa': {
    image: office2,
    name: 'Environment 3',
    id: 'workspace-media.e4b83515-c5b3-46fe-a48e-21722a341daa',
  },
  'workspace-media.9a7733d6-a704-46ff-9180-27873a08562d': {
    image: office3,
    name: 'Environment 4',
    id: 'workspace-media.9a7733d6-a704-46ff-9180-27873a08562d',
  },
  'workspace-media.33a04799-1d6b-491e-8c56-82f256f27e48': {
    image: office4,
    name: 'Environment 5',
    id: 'workspace-media.33a04799-1d6b-491e-8c56-82f256f27e48',
  },
  'workspace-media.2e70c993-b0e6-4490-b6b1-bccf986780ea': {
    image: office5,
    name: 'Environment 6',
    id: 'workspace-media.2e70c993-b0e6-4490-b6b1-bccf986780ea',
  },
  'workspace-media.84e532d1-3fa5-490e-9df5-f144c6fbfd34': {
    image: office6,
    name: 'Environment 7',
    id: 'workspace-media.84e532d1-3fa5-490e-9df5-f144c6fbfd34',
  },
};

interface Props {
  setTab: (tab: number) => void;
  module: FullModule;
}

const Character = ({ setTab, module }: Props) => {
  const [activeStep, setActiveStep] = useState(0);
  const { moduleId } = useParams();
  const queryClient = useQueryClient();

  const {
    mutate: updateCharacterMutation,
    isPending: isUpdateCharacterLoading,
  } = useMutation({
    mutationFn: (character: CharacterType) => updateCharacter(character),
  });

  const {
    mutate: updateEnvironmentMutation,
    isPending: isUpdateEnvironmentLoading,
  } = useMutation({
    mutationFn: (environment: EnvironmentType) =>
      updateEnvironment(environment),
  });

  const handleSelectCharacter = (char: string) => {
    updateCharacterMutation(
      {
        character: char,
        characterName: pics[char as keyof typeof pics]['name'],
        moduleName: module.moduleName,
        moduleId: module.moduleId,
        voiceId: pics[char as keyof typeof pics]['voiceId'],
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ['moduleDataById', moduleId],
          });
          console.log('onSuccess updateCharacterMutation');
        },
        onError: (error) => alert(error),
      },
    );
  };

  const handleSelectEnvironment = (env: string) => {
    updateEnvironmentMutation(
      {
        environment: env,
        moduleName: module.moduleName,
        moduleId: module.moduleId,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ['moduleDataById', moduleId],
          });
          console.log('onSuccess updateEnvironmentMutation');
        },
        onError: (error) => alert(error),
      },
    );
  };
  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const steps = [
    {
      header: 'Select a character',
      step: (
        <CustomSlider slidesToShow={4.5}>
          {Object.keys(pics).map((char) => (
            <div key={pics[char as keyof typeof pics]['id']}>
              <SliderItem
                name={pics[char as keyof typeof pics]['name']}
                image={pics[char as keyof typeof pics]['image']}
                imageWidth={'220px'}
                handleClick={() => handleSelectCharacter(char)}
                id={pics[char as keyof typeof pics]['id']}
                isSelected={
                  module.character === pics[char as keyof typeof pics]['id']
                }
              />
            </div>
          ))}
        </CustomSlider>
      ),
    },
    {
      header: 'Select a location for the experience',
      step: (
        <CustomSlider slidesToShow={3.5}>
          {Object.keys(envs).map((env, index) => (
            <div key={index}>
              <SliderItem
                name={envs[env as keyof typeof envs]['name']}
                image={envs[env as keyof typeof envs]['image']}
                imageWidth={'290px'}
                handleClick={() => handleSelectEnvironment(env)}
                id={envs[env as keyof typeof envs]['id']}
                isSelected={
                  module.environment === envs[env as keyof typeof envs]['id']
                }
              />
            </div>
          ))}
        </CustomSlider>
      ),
    },
  ];

  interface LayoutProps {
    step: ReactNode;
    header: string;
  }

  const wrapLayout = ({ step, header }: LayoutProps) => (
    <Layout
      header={header}
      isLoading={isUpdateCharacterLoading || isUpdateEnvironmentLoading}
    >
      {step}
    </Layout>
  );

  return (
    <CharacterContainer>
      {wrapLayout(steps[activeStep])}
      {activeStep !== steps.length - 1 ? (
        <CustomButton
          disabled={module.character === undefined}
          variant="contained"
          onClick={handleNext}
          endIcon={<ArrowDownwardIcon />}
        >
          Next
        </CustomButton>
      ) : (
        <CustomButton
          onClick={() => setTab(2)}
          variant="contained"
          disabled={module.environment === undefined}
        >
          Proceed
        </CustomButton>
      )}
    </CharacterContainer>
  );
};

export default Character;
