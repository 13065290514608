import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  color: '#FFF',
});

const MainHeader = styled(Typography)({
  fontWeight: 700,
  fontSize: 'clamp(1.875rem, 1.3248rem + 2.3474vw, 3.4375rem)',
  letterSpacing: '-1.65px',
});

const Results = () => {
  return (
    <Container>
      <MainHeader>Thanks for participating!</MainHeader>
    </Container>
  );
};

export default Results;
